import { useState, useContext } from "react";
import { v4 as uuidv4 } from "uuid";
import { VisibilityContext } from "contexts/VisibilityContext";

const useVisibilityContext = () => {
  const { visibilityMap, show, hide } = useContext(VisibilityContext);
  const [refUUID] = useState<string>(uuidv4());

  return {
    isVisible: (refUUID && visibilityMap.get(refUUID)) || false,
    show,
    hide,
    refUUID
  };
};

export default useVisibilityContext;
