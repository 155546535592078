import React, { useEffect } from "react";
import { Box } from "grommet";
import { useGenerationsFb } from "hooks/data/useGenerationsFb";
import useResponsiveContext from "hooks/useResponsiveContext";
import { Generation } from "types/interfaces";
import GeneratedImagesItem from "components/TryAIApp/AssetManager/GeneratedImagesList/GeneratedImagesItem";
import Preloader from "components/TryAIApp/Common/Preloader";
import useNotificationsContext from "hooks/useNotificationContext";
import useVisibilityContext from "hooks/useVisibilityContext";

const GeneratedImagesList: React.FC = () => {
  const { generations } = useGenerationsFb();
  const size = useResponsiveContext();
  const { data, isLoading } = generations();
  const { setNotifications } = useNotificationsContext();

  const {
    isVisible: isVisibleSeedCopied,
    hide: hideSeedCopied,
    show: showSeedCopied,
    refUUID: refUUIDSeedCopied
  } = useVisibilityContext();

  useEffect(() => {
    setNotifications([
      {
        show: isVisibleSeedCopied,
        message: "Seed is successfully copied to clipboard",
        onClose: () => hideSeedCopied(refUUIDSeedCopied),
        type: "warning"
      }
    ]);
  }, [isVisibleSeedCopied]);

  const handleNotify = () => {
    showSeedCopied(refUUIDSeedCopied);
  };

  const isMobileSize = size === "small";
  const itemBasisCount = (data?.length || 0) >= 4 ? 4 : data?.length;
  const itemBasisSize = data?.length === 1 ? "full" : `1/${itemBasisCount}`;
  const itemBasis = isMobileSize ? "full" : itemBasisSize;

  return isLoading ? (
    <Preloader />
  ) : (
    <Box direction="row" wrap={true} style={{ transition: "all 0.3s ease" }}>
      {data
        ?.sort((a, b) => {
          // Compare seconds first, but in reverse order
          if (a.createdAt.seconds !== b.createdAt.seconds) {
            return b.createdAt.seconds - a.createdAt.seconds;
          }
          // If seconds are equal, compare nanoseconds in reverse order
          return b.createdAt.nanoseconds - a.createdAt.nanoseconds;
        })
        .map((generation: Generation) => (
          <GeneratedImagesItem
            key={generation.docID}
            generation={generation}
            handleNotify={handleNotify}
            basis={itemBasis}
          />
        ))}
    </Box>
  );
};

export default GeneratedImagesList;
