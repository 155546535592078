import Preloader from "components/TryAIApp/Common/Preloader";
import useAuth from "hooks/useAuth";
import React from "react";
import type { User } from "types/types";

type AuthContextType = {
  user?: User;
  signInWithGoogle?: any;
  signOutUser?: any;
};

type AuthProviderType = {
  children: React.ReactNode;
};

export const AuthContext = React.createContext<AuthContextType>({
  user: null,
  signInWithGoogle: () => {},
  signOutUser: () => {}
});

export const AuthProvider: React.FC<AuthProviderType> = ({ children }) => {
  const { user, loading, signInWithGoogle, signOutUser } = useAuth();
  if (loading) return <Preloader />;
  return (
    <AuthContext.Provider value={{ user, signInWithGoogle, signOutUser }}>
      <>{children}</>
    </AuthContext.Provider>
  );
};
