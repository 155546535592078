import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Box, Button, ResponsiveContext, Text } from "grommet";
import Logo from "components/TryAIApp/Common/Logo";
import { useCustomerFb } from "hooks/data/useCustomerFb";
import Preloader from "components/TryAIApp/Common/Preloader";
import useCustomerID from "hooks/useCustomerID";

const RecoverID = () => {
  const { customerID } = useParams();
  const { findByID } = useCustomerFb();
  const navigate = useNavigate();
  const { saveCustomerID } = useCustomerID();

  const customer = findByID(customerID as string);
  if (customer.isSuccess) {
    saveCustomerID(customerID as string);
  }

  return (
    <Box gap="large" pad="large" justify="center" align="center">
      <Box align="center">
        <ResponsiveContext.Consumer>
          {(size) => (
            <Box>
              <Logo size={size}>{process.env.REACT_APP_LOGO}</Logo>
            </Box>
          )}
        </ResponsiveContext.Consumer>
      </Box>

      <Box pad="large" justify="center" gap="large" width="medium">
        {customer.isLoading && <Preloader />}
        {customer.isSuccess && (
          <Text textAlign="center">
            Your CustomerID is successfully recovered {customerID}.
          </Text>
        )}
        {customer.isError && (
          <Text textAlign="center">
            CustomerID {customerID} not found. Please correct it and try again.
          </Text>
        )}

        <Button primary label="Go to Home" onClick={() => navigate("/")} />
      </Box>
    </Box>
  );
};

export default RecoverID;
