import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Box, Nav, Sidebar, Anchor, Heading } from "grommet";
import version from "utils/version";
import { UserSettings } from "grommet-icons";
import Logo from "components/TryAIApp/Common/Logo";
import UserConfigs from "components/TryAIApp/Common/UserConfigs";
import useResponsiveContext from "hooks/useResponsiveContext";
import useVisibilityContext from "hooks/useVisibilityContext";
import useAuthContext from "hooks/useAuthContext";

type SidebarIsMobileSize = {
  isMobileSize?: boolean;
};

type ButtonProps = React.ComponentProps<typeof Button>;

interface SidebarButtonProps extends ButtonProps {
  icon: JSX.Element | undefined;
  label?: string;
  selected?: boolean;
}

export interface SidebarWrapperProps {
  steps: {
    title: string;
    icon: JSX.Element | undefined;
    onClick: () => void;
  }[];
  activeStep: number;
}

interface SidebarFooterProps extends SidebarIsMobileSize {
  refUUID: string;
}

const SidebarHeader: React.FC<SidebarIsMobileSize> = ({ isMobileSize }) => (
  <Box align="center" gap="small" pad="small" direction="row" justify="center">
    <Logo size="small">
      {isMobileSize
        ? process.env.REACT_APP_LOGO?.charAt(0)
        : process.env.REACT_APP_LOGO}
    </Logo>
  </Box>
);

const SidebarButton: React.FC<SidebarButtonProps & SidebarIsMobileSize> = ({
  icon,
  label,
  selected,
  isMobileSize,
  onClick,
  ...rest
}) => (
  <Box
    pad={{ left: "medium", right: "medium", vertical: "small" }}
    background={selected ? "text" : ""}
  >
    <Button
      gap="medium"
      alignSelf={isMobileSize ? "center" : "start"}
      plain
      icon={icon}
      onClick={onClick}
      label={isMobileSize ? "" : label}
      {...rest}
    />
  </Box>
);

const SidebarFooter: React.FC<SidebarFooterProps> = ({
  isMobileSize,
  refUUID
}) => {
  const { show } = useVisibilityContext();
  const navigate = useNavigate();
  const { user } = useAuthContext();

  return (
    <Nav aria-label="sidebar footer">
      <SidebarButton
        onClick={(e) =>
          (e.target as Element).localName === "div" || !user
            ? navigate("/changelog")
            : show(refUUID)
        }
        icon={<UserSettings />}
        label={isMobileSize ? "" : `v${version}`}
      />
    </Nav>
  );
};

const MainNavigation: React.FC<SidebarWrapperProps & SidebarIsMobileSize> = ({
  steps,
  activeStep,
  isMobileSize
}) => (
  <Box overflow={{ horizontal: "auto" }} fill>
    <Nav aria-label="main navigation" gap="none">
      {steps.map((step, index) => (
        <SidebarButton
          key={index}
          icon={step.icon}
          label={step.title}
          isMobileSize={isMobileSize}
          onClick={step.onClick}
          selected={activeStep === index}
        />
      ))}
    </Nav>
  </Box>
);

const SidebarWrapper: React.FC<SidebarWrapperProps> = ({
  steps,
  activeStep
}) => {
  const { isVisible, hide, refUUID } = useVisibilityContext();
  const size = useResponsiveContext();
  const isMobileSize = size === "small";
  return (
    <>
      <Sidebar
        gap="none"
        pad="none"
        background="#2c387e"
        basis={isMobileSize ? "xxsmall" : "medium"}
        header={<SidebarHeader isMobileSize={isMobileSize} />}
        footer={<SidebarFooter refUUID={refUUID} isMobileSize={isMobileSize} />}
      >
        <MainNavigation
          steps={steps}
          activeStep={activeStep}
          isMobileSize={isMobileSize}
        />
        <Box align="center">
          {/* Replace the href and src attributes with the actual links and logos of the partners */}
          <Anchor href="https://guelmanundunbekannt.com/" color="text">
            <Heading level="5">
              Supported by: <br /> GUELMAN UND UNBEKANNT
            </Heading>
          </Anchor>
        </Box>
      </Sidebar>

      <UserConfigs onClose={() => hide(refUUID)} show={isVisible} />
    </>
  );
};

export default SidebarWrapper;
