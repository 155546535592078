import React from "react";
import { Heading, Page, PageContent, Text } from "grommet";

const TermsOfUse: React.FC = () => {
  return (
    <Page background="light-3" kind="narrow" pad={{ bottom: "medium" }}>
      <PageContent>
        <Heading>User Content Policy</Heading>

        <Text>
          <Heading level="2">Introduction</Heading>
          ArtArt AI champions creativity by merging art and technology, enabling
          users to explore various artistic styles and artists to monetize their
          work. We embrace all forms of artistic expression, ensuring a safe and
          enjoyable platform experience through adherence to our Terms of Use
          and content rules.
        </Text>
        <Text>
          <Heading level="2">Content Guidelines</Heading>
          While we celebrate freedom in artistic expression, not all content is
          allowed on our platform. Users must adhere to our Rules and Terms of
          Service to maintain a secure and pleasant environment for all.
        </Text>
        <Text>
          <Heading level="2">Non-Compliance Consequences</Heading>
          Failure to comply with these Rules may necessitate modifications to
          your usage. Persistent or severe infractions can lead to further
          actions, such as account suspension or termination.
        </Text>
        <Text>
          <Heading level="2">Prohibited Content</Heading>
          <ul>
            <li>
              <b>Child Sexual Abuse Material:</b> We strictly prohibit content
              exploiting or endangering children, either real or fictional, and
              any material promoting child sexual abuse or grooming behaviors.
            </li>
          </ul>
          <ul>
            <li>
              <b>Dangerous Content:</b> Content that incites hatred, harassment,
              or poses a risk of serious harm or death is not allowed. This
              includes promoting violence or hatred against individuals or
              groups based on various characteristics and glorifying terrorism
              or violent extremism.
            </li>
          </ul>
          <ul>
            <li>
              <b>Deceptive Content:</b> Impersonating others to deceive,
              including mimicking names, images, or descriptions of existing
              creators or entities, is prohibited.
            </li>
          </ul>
          <ul>
            <li>
              <b>Sensitive Content:</b> We disallow content that showcases
              excessive violence, gore, or other shocking imagery, and that
              promotes themes related to sexual violence, incest, or bestiality.
            </li>
          </ul>
          <ul>
            <li>
              <b>Illegal Content:</b> Content that breaches applicable laws and
              regulations is not permitted.
            </li>
          </ul>
        </Text>
        <Text>
          <Heading level="2">Reporting Issues</Heading>
          If you encounter an issue with content on ArtArtAI, please report it
          to us at: <b>support@artart.ai</b>.
        </Text>
      </PageContent>
    </Page>
  );
};

export default TermsOfUse;
