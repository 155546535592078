const WsProtocol = process.env.REACT_APP_WS_SSL === "true" ? "wss" : "ws";
const HttpProtocol = process.env.REACT_APP_WS_SSL === "true" ? "https" : "http";

export const httpURL = `${HttpProtocol}://${process.env.REACT_APP_WS_HOST}:${process.env.REACT_APP_WS_POST}`;

export default new WebSocket(
  `${WsProtocol}://${process.env.REACT_APP_WS_HOST}:${
    process.env.REACT_APP_WS_POST
  }/?jwt=${localStorage.getItem("websocket.authToken")}`
);

export interface WebSocketLoginResponse {
  token: string;
}

export const webSocketLogin = async (
  clientID: string
): Promise<WebSocketLoginResponse> => {
  const response = await fetch(`${httpURL}/login`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ clientID })
  });

  if (!response.ok) {
    throw new Error("Login failed");
  }

  return response.json();
};
