import React from "react";
import { Box, Spinner } from "grommet";

const Preloader: React.FC = () => {
  return (
    <Box fill align="center" justify="center">
      <Spinner size="large" />
    </Box>
  );
};

export default Preloader;
