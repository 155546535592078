import { useState, useEffect } from "react";
import { CustomerID } from "types/types";

function useCustomerID() {
  const savedCustomerID = localStorage.getItem("customerID");
  const [customerID, setCustomerID] = useState<CustomerID>(
    savedCustomerID || "noexists"
  );

  useEffect(() => {
    // Read session ID from localStorage on component mount
    if (savedCustomerID) {
      setCustomerID(savedCustomerID);
    }
  }, []);

  const saveCustomerID = (newCustomerID: CustomerID) => {
    // Save session ID to localStorage
    if (customerID === "noexists" || customerID !== newCustomerID) {
      localStorage.setItem("customerID", newCustomerID);
      setCustomerID(newCustomerID);
    }
  };

  const clearCustomerID = () => {
    localStorage.removeItem("customerID");
  };

  return { saveCustomerID, clearCustomerID, customerID, savedCustomerID };
}

export default useCustomerID;
