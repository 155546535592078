import AWS from "aws-sdk";
import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { generateUniqueFileName, getFileExtension } from "utils/helper";
// TODO ADD FEATURE FLAGS TO ENABLE
// import imagingService from "utils/imagingService";

interface UseUploader {
  onSuccessUpload: () => void;
  onFailedUpload: () => void;
  onUpload: (url: string) => void;
}

const useUploader = ({
  onSuccessUpload,
  onFailedUpload,
  onUpload
}: UseUploader) => {
  const [isLoading, setIsLoading] = useState(false);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    acceptedFiles.forEach((file: File) => {
      const reader = new FileReader();

      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = () => {
        // Do whatever you want with the file contents
        setIsLoading(true);
        const binaryStr = reader.result;

        // Configure AWS with your access and secret key.
        const {
          REACT_APP_AWS_ACCESS_KEY,
          REACT_APP_AWS_SECRET_ACCESS_KEY,
          REACT_APP_AWS_REGION,
          REACT_APP_AWS_BUCKET
        } = process.env; // Replace with your keys

        AWS.config.update({
          region: REACT_APP_AWS_REGION, // Replace with your region
          accessKeyId: REACT_APP_AWS_ACCESS_KEY,
          secretAccessKey: REACT_APP_AWS_SECRET_ACCESS_KEY
        });

        const S3 = new AWS.S3();
        if (binaryStr && REACT_APP_AWS_BUCKET) {
          const newFileName = generateUniqueFileName(
            getFileExtension(file.name)
          );
          const params: AWS.S3.Types.PutObjectRequest = {
            Bucket: REACT_APP_AWS_BUCKET,
            Key: newFileName,
            Body: binaryStr,
            ACL: "public-read"
          };

          const upload = S3.upload(params);

          upload.send(function (
            err: AWS.AWSError,
            data: AWS.S3.ManagedUpload.SendData
          ) {
            if (err) {
              console.error(`File upload error: ${err}`);
              onFailedUpload();
            } else {
              console.log(`File uploaded successfully at ${data.Location}`);
              onSuccessUpload();
              // With coustom domain
              onUpload(`http://${data.Bucket}/${newFileName}`);
              // Without coustom domain
              // onUpload(data.Location);
            }
            setIsLoading(false);
          });
        }
      };
      reader.readAsArrayBuffer(file);
    });
  }, []);

  const acceptedFileTypes = {
    "image/jpeg": [".jpeg", ".jpg"],
    "image/png": [".png"],
    "image/webp": [".webp"]
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: acceptedFileTypes
  });
  return {
    getRootProps,
    getInputProps,
    isLoading
  };
};

export default useUploader;
