import { useQuery } from "@tanstack/react-query";
import { doc, getDoc } from "firebase/firestore";
import { db } from "utils/firebase";
import { Config } from "types/interfaces";

const collectionName = "configs";
const docID = "global";

export const useConfigFb = () => {
  const { data, isLoading, isError, isFetched } = useQuery(
    [collectionName],
    async () => {
      const docRef = doc(db, collectionName, docID);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        return { ...docSnap.data(), docID: docSnap.id } as Config;
      } else {
        throw new Error("Config not found");
      }
    }
  );

  return {
    config: data,
    fetchStatus: {
      isLoading,
      isError,
      isFetched
    }
  };
};
