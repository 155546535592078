export const promptTextPaintingTechniqueOptions = [
  "Illustration",
  { name: "Oil on canvas", value: "Oil on canvas painting" },
  "Acrylic painting",
  "Pen Drawing",
  "Graffiti Art",
  { name: "Kinetic Art", value: "Kinetic Art picture" },
  "Sculpture",
  { name: "Watercolor", value: "Watercolor painting" },
  { name: "Digital Art", value: "Digital Art picture" },
  "Charcoal Drawing",
  { name: "Ink Wash", value: "Ink Wash painting" },
  "Collage",
  "Ceramics",
  { name: "Textile Art", value: "Textile Art picture" },
  "Engraving",
  { name: "Stencil Art", value: "Stencil Art picture" },
  "Woodcut",
  "Glassblowing",
  "Metalworking",
  "Photography",
  "Mixed Media",
  "Pointillism",
  "Calligraphy",
  "Fresco",
  "Pottery",
  "Mosaic",
  "Decoupage",
  { name: "Mural", value: "Mural Painting" },
  { name: "Airbrushing", value: "Airbrushing painting" },
  "Tapestry",
  "Lithography",
  "Screen Printing",
  "Marbling",
  "Quilling",
  "Gyotaku (Fish Printing)",
  "Beehive Mud Hut Art",
  "Reverse Glass Painting",
  "Paper Cutting",
  "Sand Painting",
  "String Art",
  "Trompe-l'oeil",
  "Ikebana",
  "Kintsugi",
  "Stained Glass",
  "Sketch",
  "Drawing",
  "Doodle",
  "Children's Drawing",
  { name: "Dot Art", value: "Dot Art painting" },
  { name: "Line Art", value: "Line Art painting" },
  "Hand-Drawn",
  "Storybook Illustration",
  "Cartographic",
  "Realistic Illustration",
  "Abstract Sketch",
  "Whimsical Doodle",
  "Crayon Children's Drawing",
  "Ink Stipple",
  "Charcoal Hand-Drawn",
  "Watercolor Storybook Illustration",
  "Vintage Cartographic Drawing",
  "Digital Illustration",
  "Pencil Sketch",
  "Graphic Drawing",
  "Zentangle Doodle",
  "Chalk Children's Drawing",
  "Hatching and Cross-Hatching",
  "Cartoon Drawing",
  "Pop Art Illustration",
  "Surreal Sketch",
  "Pastel Drawing",
  "Marker Doodle",
  "Anime/Manga Drawing",
  "Paper Cutout Art",
  "Silhouette Illustration",
  "Impressionistic Landscape Painting",
  "Pointillism Drawing",
  "Architectural Illustration",
  "Fashion Sketch",
  "Still Life Drawing",
  "Woodblock Print",
  "Digital Doodle",
  "Clay Sculpture",
  "Geometric Line Art",
  "Scratchboard Illustration",
  "Caricature Drawing"
];
export const promptTextSceneStyleOptions = [
  "Cinematic",
  "Hyper Realistic",
  "Cartoon",
  "Documentary",
  "Historical",
  "Illustration",
  "Acrylic painting",
  "Pen Drawing",
  "Graffiti Art",
  "Sculpture",
  "Charcoal Drawing",
  "Collage",
  "Ceramics",
  "Engraving",
  "Woodcut",
  "Glassblowing",
  "Metalworking",
  "Photography",
  "Mixed Media",
  "Pointillism",
  "Calligraphy",
  "Fresco",
  "Pottery",
  "Mosaic",
  "Decoupage",
  "Long exposure photo",
  "Surreal",
  "Abstract",
  "Sci-Fi",
  "Film Noir",
  "Urban",
  "Minimalist",
  "Fantasy",
  "Steampunk",
  "Gothic",
  "Futuristic",
  "Underwater",
  "Cyberpunk",
  "Vintage",
  "Retro",
  "Noir",
  "Pop Art",
  "Impressionist",
  "Expressionist",
  "Surrealist",
  "Gritty",
  "Whimsical",
  "Minimalistic",
  "Avant-Garde",
  "Glamour",
  "Mystery",
  "Business",
  "Romance",
  "Horror",
  "Science fiction",
  "Comedy",
  "Drama",
  "Action/Adventure",
  "Thriller",
  "Suspense",
  "Satire",
  "Biography",
  "Autobiography",
  "Young adult",
  "Children's",
  "Poetry",
  "Classic",
  "Literary fiction",
  "Non-fiction",
  "Crime",
  "Western",
  "Magical realism",
  "Dystopian",
  "Post-apocalyptic",
  "Urban fantasy",
  "Paranormal romance",
  "Historical romance",
  "Science fantasy",
  "Psychological",
  "Adventure",
  "Supernatural",
  "Time travel",
  "Espionage",
  "War",
  "Family saga",
  "Coming-of-age",
  "Social commentary",
  "Mythology",
  "Fairy tale",
  "Fable",
  "Historical fiction",
  "Travelogue",
  "Journal",
  "Essay",
  "Short story",
  "Novella",
  "Graphic novel"
];

export const promptTextCameraOptions = [
  "Bokeh",
  "Color Grading",
  "Photojournalism",
  "Tri-X 400 TX",
  "Macro",
  "Shot on 70mm",
  "Kodak Gold 200",
  "Long Exposure",
  "Black and White",
  "Fashion",
  "High Contrast",
  "Vintage",
  "Minimalist",
  "Infrared",
  "Double Exposure",
  "Astrophotography",
  "Silhouette",
  "Low Light",
  "Street",
  "Underwater",
  "Dramatic",
  "Panorama",
  "Pinhole",
  "Time-lapse",
  "Portrait",
  "Abstract",
  "HDR",
  "Candid",
  "Nature",
  "Still Life",
  "Architectural",
  "Wedding",
  "Night",
  "Landscape",
  "Sports",
  "Food",
  "Wildlife",
  "Urban",
  "Fine Art",
  "Travel",
  "Documentary",
  "Product",
  "Aerial",
  "Experimental",
  "Surreal",
  "Film Noir",
  "Gritty",
  "Monochrome",
  "Vintage Polaroid",
  "Cinematic",
  "Drone",
  "Ethnographic",
  "Retro"
];

export const promptTextShotTypeOptions = [
  "Extreme Long Shot",
  "Wide Shot",
  "Medium Shot",
  "Closeup Shot",
  "Full Shot",
  "Drone Shot",
  "Over-the-Shoulder Shot",
  "Point-of-View Shot (POV)",
  "Two-Shot",
  "Three-Shot",
  "Establishing Shot",
  "Low-Angle Shot",
  "High-Angle Shot",
  "Dutch Angle Shot",
  "Bird's Eye View Shot",
  "Worm's Eye View Shot",
  "Aerial Shot",
  "Crane Shot",
  "Steadicam Shot",
  "Tracking Shot",
  "Dolly Shot",
  "Handheld Shot",
  "Zoom Shot",
  "Rack Focus Shot",
  "Reverse Shot",
  "Split Screen Shot",
  "Montage Shot",
  "Insert Shot",
  "Cutaway Shot",
  "Freeze Frame Shot",
  "Slow Motion Shot",
  "Fast Motion Shot",
  "Flashback Shot",
  "Flash Forward Shot",
  "Single Take Shot",
  "Night Vision Shot"
];

export const promptTextColorOptions = [
  "Warm Color Palette",
  "Cool Color Palette",
  "Colorful",
  "Black and White",
  "Tetrachromacy",
  "Monochrome",
  "Vintage Polaroid",
  "Cinematic",
  "Pastel Dream",
  "Earth Tones",
  "Vibrant Neon",
  "Autumn Leaves",
  "Ocean Blues",
  "Sunset Glow",
  "Spring Blossoms",
  "Midnight Hues",
  "Desert Sands",
  "Jewel Tones",
  "Citrus Burst",
  "Serene Sky",
  "Rustic Charm",
  "Electric Pop",
  "Enchanted Forest",
  "Muted Elegance",
  "Tropical Paradise",
  "Ice Queen",
  "Fiery Inferno",
  "Dusky Romance",
  "Fresh Mint",
  "Golden Hour",
  "Urban Chic",
  "Soft Serenity",
  "Rich Velvet",
  "Candy Coated",
  "Regal Purple",
  "Country Cottage",
  "Zen Garden",
  "Gilded Glamour",
  "Moody Blues",
  "Opulent Gold",
  "Arctic Frost",
  "Bohemian Rhapsody",
  "Summer Citrus",
  "Royal Red",
  "Whimsical Wonderland",
  "Industrial Edge",
  "Citified Chic",
  "Chocolate Delight",
  "Cosmic Galaxy",
  "Watercolor Splash",
  "Rustic Farmhouse",
  "Pop Art Punch",
  "Enigmatic Noir",
  "Beachside Bliss",
  "Rainbow Bright",
  "Scandinavian Simplicity",
  "Enchanted Mermaid",
  "Vintage Romance"
];

export const promptTextFiltersOptions = [
  "RGB Displacement",
  "Anaglyph",
  "Posterization",
  "Grayscale",
  "Edge Detection",
  "Vignette",
  "Dithering",
  "Emboss",
  "Infrared",
  "Color Inversion",
  "Pixelate",
  "Crosshatch",
  "Watercolor",
  "Oil Painting",
  "Pencil Sketch",
  "Pop Art",
  "Sepia",
  "Solarize",
  "Glow",
  "Cartoonize",
  "Pointillism",
  "Halftone",
  "Fisheye",
  "Cubism",
  "Texturize",
  "Mosaic",
  "Swirl",
  "Tilt Shift",
  "Gaussian Blur",
  "Radial Blur",
  "Kaleidoscope",
  "Glitch",
  "Vintage",
  "Film Grain",
  "Color Splash",
  "Soft Focus",
  "Chromatic Aberration",
  "Low Poly",
  "Rainbow",
  "Disco",
  "X-Ray"
];
