import React, { useEffect, useState } from "react";
import { Image as GrommetImage } from "grommet";
import { getFileNameFromURL } from "utils/helper";
import { getThumbURL } from "utils/storageService";
import { ThumbSize } from "types/types";

interface LazyImageProps {
  src: string;
  fill: boolean;
  size: ThumbSize;
}

const LazyImage: React.FC<LazyImageProps> = ({ src, fill, size }) => {
  const [loaded, setLoaded] = useState(false);
  const [fileUrl, setFileUrl] = useState<string | null>(null); // Add this line to manage the fileUrl state

  useEffect(() => {
    const thumbFileName = getFileNameFromURL(src);
    const thumbUrl = async () => {
      const request = await getThumbURL(thumbFileName || "", size);
      return request;
    };
    thumbUrl().then((url) => {
      const img = new Image();
      img.onload = () => {
        setLoaded(true);
        setFileUrl(url); // Update the fileUrl stat
      };
      img.src = url;
    });
  }, [src]);

  return (
    <GrommetImage
      src={fileUrl || ""} // Use fileUrl if available, otherwise fallback to src
      fill={fill}
      style={{
        opacity: loaded ? 1 : 0,
        transition: "opacity 0.5s ease-in-out"
      }}
    />
  );
};

export default LazyImage;
