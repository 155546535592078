import React from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
// import { grommetTheme } from "configs/theme";
import { hpe } from "grommet-theme-hpe";
import DefaultUI from "components/TryAIApp/index";
import { VisibilityProvider } from "contexts/VisibilityContext";
import { MutationsProvider } from "contexts/MutationsContext";
import { Grommet } from "grommet";
import { ClientStateProvider } from "contexts/ClientStateContext";
import { AuthProvider } from "contexts/AuthContext";

function App() {
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <Grommet theme={hpe} full themeMode="light">
        <AuthProvider>
          <ClientStateProvider>
            <VisibilityProvider>
              <MutationsProvider>
                <DefaultUI />
              </MutationsProvider>
            </VisibilityProvider>
          </ClientStateProvider>
        </AuthProvider>
      </Grommet>
      <ReactQueryDevtools initialIsOpen={true} position="top-right" />
    </QueryClientProvider>
  );
}

export default App;
