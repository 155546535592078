import React, { ReactNode } from "react";
import { Box, Heading, Tip } from "grommet";
import { CircleInformation } from "grommet-icons";

interface InformationHeaderProps {
  title: string;
  children?: string | ReactNode;
}

const InformationHeader: React.FC<InformationHeaderProps> = ({
  title,
  children
}) => {
  return (
    <Box
      direction="row"
      align="center"
      gap="small"
      fill="horizontal"
      height={{ min: "fit-content" }}
    >
      <Heading level={3} margin="none" fill>
        {title}
      </Heading>
      <Box margin={{ right: "small" }}>
        <Tip
          content={children}
          dropProps={{
            align: { top: "bottom", left: "right" },
            width: "large"
          }}
        >
          <CircleInformation size="medium" />
        </Tip>
      </Box>
    </Box>
  );
};

export default InformationHeader;
