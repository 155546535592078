import React from "react";
import { Heading, Page, PageContent, Text } from "grommet";

const TermsOfUse: React.FC = () => {
  return (
    <Page background="light-3" kind="narrow" pad={{ bottom: "medium" }}>
      <PageContent>
        <Heading>Terms Of Use</Heading>

        <Text>
          <Heading level="2">Introduction</Heading>
          ArtArt AI, dedicated to fostering creative expression and safeguarding
          artists' intellectual property in the age of generative AI, ensures
          artists maintain ownership over generative works produced with their
          models. Our platform allows user creativity to flourish while
          preserving artists' copyright over works generated using their models.
          Please review these Terms of Use attentively.
        </Text>
        <Text>
          <Heading level="2">Registration and Access</Heading>
          The Terms of Use outline the legal conditions governing your use of
          services by ArtArt AI Ltd, including various forms of content and
          software related to our services. By utilizing our services, you agree
          to these Terms, which also encompass our Content Rules, Copyright
          Policy, and other written guidelines. Both individuals and
          corporations can register and access ArtArt AI, adhering to specific
          age and authority requirements.
        </Text>
        <Text>
          <Heading level="2">Content and Intellectual Property Rights</Heading>
          ArtArt AI maintains ownership of all Intellectual Property (IP)
          related to the Services. Users may contribute content, and if you are
          the Author of a Model, you retain ownership of it and any generated
          Content, granting ArtArt AI specific usage rights. If you are not the
          Author, any generated Content using a Model is owned by the Author.
        </Text>
        <Text>
          <Heading level="2">Fees and Payments</Heading>
          ArtArt AI manages payments for Authors of Models and charges fees
          according to its pricing terms, visible on ArtArt AI. Payment and
          billing details must be accurate, and various payment methods are
          accepted.
        </Text>
        <Text>
          <Heading level="2">
            Confidentiality, Security, and Data Protection
          </Heading>
          Users may access ArtArt AI's Confidential Information, which must be
          protected and not disclosed. Users must also secure their use of the
          Services and comply with data protection laws and ArtArt AI’s Privacy
          Policy.
        </Text>
        <Text>
          <Heading level="2">Term and Termination</Heading>
          The Terms are effective upon your first use of the Services and remain
          valid until terminated by either party. Termination can occur under
          various conditions, and certain sections survive termination.
        </Text>
        <Text>
          <Heading level="2">
            Indemnification; Disclaimer of Warranties; Limitations on Liability
          </Heading>
          The Terms are governed by the laws of England and Wales, and any
          claims related to them are subject to the jurisdiction of the English
          courts.
        </Text>
        <Text>
          <Heading level="2">Miscellaneous</Heading>
          The Terms do not establish a partnership or agency relationship
          between the user and ArtArt AI. They may not be assigned by the user
          but may be assigned by ArtArt AI under certain conditions. Amendments
          to the Terms can be made by ArtArt AI, and continued use of the
          Services implies agreement. Non-action regarding a violation of the
          Terms does not waive ArtArt AI’s rights, and if any term is deemed
          unenforceable, it does not affect other terms. The Terms, along with
          any incorporated policies, represent the entire agreement between the
          user and ArtArt AI regarding the use of the Services.
        </Text>
      </PageContent>
    </Page>
  );
};

export default TermsOfUse;
