import { db } from "utils/firebase";
import {
  collection,
  doc,
  getDoc,
  addDoc,
  setDoc,
  getDocs,
  deleteDoc,
  updateDoc
} from "firebase/firestore";
import { syncedData } from "utils/helper";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import useClientStateContext from "hooks/useClientStateContext";

export const useSharedFb = () => {
  const queryClient = useQueryClient();
  const { customerID } = useClientStateContext();

  const findOrCreateDoc = async (
    defaultValues: any,
    collectionName: string
  ) => {
    const docRef = doc(db, collectionName, customerID);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const syncedSnap = syncedData(docSnap.data(), defaultValues);
      return { ...syncedSnap, docID: docSnap.id };
    } else {
      await setDoc(docRef, defaultValues);
      return { ...defaultValues, customerID };
    }
  };

  const findOrCreate = (
    defaultValues: { [key: string]: any },
    collectionName: string
  ) => {
    return useQuery(
      [collectionName, customerID],
      () =>
        customerID === ""
          ? null
          : findOrCreateDoc(defaultValues, collectionName),
      {
        initialData: defaultValues
      }
    );
  };

  const updateDocID = useMutation(
    async ({
      oldDocID,
      newDocID,
      collectionName,
      nestedCollectionName
    }: {
      oldDocID: string;
      newDocID: string;
      collectionName: string;
      nestedCollectionName?: string;
    }) => {
      const oldDocRef = doc(db, collectionName, oldDocID);
      const newDocRef = doc(db, collectionName, newDocID);
      const oldDocSnap = await getDoc(oldDocRef);

      if (oldDocSnap.exists()) {
        const data = oldDocSnap.data();
        await setDoc(newDocRef, data);
      }

      if (nestedCollectionName) {
        const oldNestedCollectionRef = collection(
          oldDocRef,
          nestedCollectionName
        );
        const oldNestedCollectionSnap = await getDocs(oldNestedCollectionRef);

        oldNestedCollectionSnap.docs.forEach(async (oldDoc) => {
          const nestedDocRef = doc(newDocRef, nestedCollectionName, oldDoc.id);

          await setDoc(nestedDocRef, oldDoc.data());
        });
      }
      await deleteDoc(oldDocRef);
      return collectionName;
    },
    {
      onSuccess: (collectionName: string) => {
        queryClient.invalidateQueries([collectionName]);
      }
    }
  );

  const updateMutation = useMutation(
    async ({
      updatedData,
      docID,
      collectionName
    }: {
      updatedData: any;
      docID: string;
      collectionName: string;
    }) => {
      const docRef = doc(db, collectionName, docID);
      await updateDoc(docRef, updatedData);
      return collectionName;
    },
    {
      onSuccess: (collectionName: string) => {
        queryClient.invalidateQueries([collectionName]);
      }
    }
  );

  const removeItemMutation = useMutation(
    async ({
      docID,
      collectionName,
      nestedCollectionName,
      onSuccess = () => null
    }: {
      docID: string;
      collectionName: string;
      nestedCollectionName: string;
      onSuccess?: () => void;
    }) => {
      const parentDocRef = doc(db, collectionName, customerID);
      const nestedCollectionRef = doc(
        parentDocRef,
        nestedCollectionName,
        docID
      );

      await deleteDoc(nestedCollectionRef);
      return { collectionName, nestedCollectionName, onSuccess };
    },
    {
      onSuccess: ({ collectionName, nestedCollectionName, onSuccess }) => {
        queryClient.invalidateQueries([collectionName, nestedCollectionName]);
        onSuccess();
      }
    }
  );

  const getItems = (collectionName: string, nestedCollectionName: string) =>
    useQuery([collectionName, nestedCollectionName], async () => {
      const docRef = doc(db, collectionName, customerID);

      const querySnapshot = await getDocs(
        collection(docRef, nestedCollectionName)
      );

      return querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        docID: doc.id
      })) as any[];
    });

  const addItemMutation = useMutation(
    async ({
      newData,
      collectionName,
      nestedCollectionName,
      onSuccess = () => null
    }: {
      newData: any;
      collectionName: string;
      nestedCollectionName: string;
      onSuccess?: () => void;
    }) => {
      const parentDocRef = doc(db, collectionName, customerID);

      // To avoid duplicate entries, add to field md5
      const hash = newData.md5 || undefined;
      if (hash) {
        const nestedCollectionRef = doc(
          parentDocRef,
          nestedCollectionName,
          hash
        );
        await setDoc(nestedCollectionRef, newData);
      } else {
        const nestedCollectionRef = collection(
          parentDocRef,
          nestedCollectionName
        );
        await addDoc(nestedCollectionRef, newData);
      }

      return { collectionName, nestedCollectionName, onSuccess };
    },
    {
      onSuccess: ({ collectionName, nestedCollectionName, onSuccess }) => {
        queryClient.invalidateQueries([collectionName, nestedCollectionName]);
        onSuccess();
      }
    }
  );

  return {
    findOrCreate,
    updateMutation: updateMutation.mutate,
    addItemMutation: addItemMutation.mutate,
    removeItemMutation: removeItemMutation,
    customerID,
    updateDocID: updateDocID.mutate,
    getItems
  };
};
