import React, { useCallback, useEffect } from "react";
import { Card, Text } from "grommet";
import Preloader from "components/TryAIApp/Common/Preloader";
import useVisibilityContext from "hooks/useVisibilityContext";
import useUploader from "hooks/useUploader";
import useNotificationsContext from "hooks/useNotificationContext";

interface DropZoneProps {
  onUpload: (url: string) => void;
}

const DropZone: React.FC<DropZoneProps> = ({ onUpload }) => {
  // Hooks
  const {
    isVisible: isVisibleFailedUploaded,
    hide: hideFailedUploaded,
    show: showFailedUploaded,
    refUUID: refUUIDFailedUploaded
  } = useVisibilityContext();

  const {
    isVisible: isVisibleSuccessfulUploaded,
    hide: hideSuccessfulUploaded,
    show: showSuccessfulUploaded,
    refUUID: refUUIDSuccessfulUploaded
  } = useVisibilityContext();

  const { setNotifications } = useNotificationsContext();

  // Memos, callbacks and effects

  useEffect(() => {
    setNotifications([
      {
        show: isVisibleSuccessfulUploaded,
        message: "File uploaded successfully",
        onClose: () => hideSuccessfulUploaded(refUUIDSuccessfulUploaded),
        type: "warning"
      },
      {
        show: isVisibleFailedUploaded,
        message: "Filed to upload file",
        onClose: () => hideFailedUploaded(refUUIDFailedUploaded),
        type: "error"
      }
    ]);
  }, [isVisibleSuccessfulUploaded, isVisibleFailedUploaded]);

  const onSuccessUpload = useCallback(() => {
    showSuccessfulUploaded(refUUIDSuccessfulUploaded);
  }, [refUUIDSuccessfulUploaded, isVisibleSuccessfulUploaded]);

  const onFailedUpload = useCallback(() => {
    showFailedUploaded(refUUIDFailedUploaded);
  }, [refUUIDFailedUploaded, isVisibleSuccessfulUploaded]);

  const { getRootProps, getInputProps, isLoading } = useUploader({
    onUpload: onUpload,
    onSuccessUpload,
    onFailedUpload
  });

  if (isLoading) return <Preloader />;
  return (
    <Card
      {...getRootProps()}
      pad="xlarge"
      align="center"
      background="brand"
      justify="center"
    >
      <input {...getInputProps()} />
      <Text textAlign="center">
        Drag 'n' drop some files here, or click to select files
      </Text>
    </Card>
  );
};

export default DropZone;
