import React, { useEffect } from "react";
import { Layer, Box, Button, Text } from "grommet";
import { StatusGood, FormClose } from "grommet-icons";

export interface NotificationType {
  message: string;
  onClose: () => void;
  type: "warning" | "error" | "ok";
  show: boolean;
}

const Notification: React.FC<NotificationType> = ({
  message,
  onClose,
  type,
  show
}) => {
  useEffect(() => {
    if (show) {
      setTimeout(() => {
        onClose();
      }, 5000);
    }
  }, [show]);

  if (!show) {
    return null;
  }

  return (
    <Layer
      position="top"
      modal={false}
      margin={{ vertical: "medium" }}
      onEsc={onClose}
      responsive={false}
      plain
    >
      <Box
        align="center"
        direction="row"
        gap="small"
        justify="between"
        round="medium"
        elevation="medium"
        pad={{ vertical: "xsmall", horizontal: "small" }}
        background={`status-${type}`}
      >
        <Box align="center" direction="row" gap="xsmall">
          <StatusGood />
          <Text>{message}</Text>
        </Box>
        <Button icon={<FormClose />} onClick={onClose} plain />
      </Box>
    </Layer>
  );
};

export default Notification;
