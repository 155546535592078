import React, { useState } from "react";

// Define the shape of the context
type VisibilityStateContextType = {
  visibilityMap: Map<string, boolean>;
  show: (refUUID: string) => void;
  hide: (refUUID: string) => void;
};

// Define the type for the Visibility component's props
type VisibilityProps = {
  children: React.ReactNode;
};

// Create the context with a default value
export const VisibilityContext =
  React.createContext<VisibilityStateContextType>({
    visibilityMap: new Map(),
    show: () => {},
    hide: () => {}
  });

export const VisibilityProvider: React.FC<VisibilityProps> = ({ children }) => {
  const [visibilityMap, setVisibilityMap] = useState<Map<string, boolean>>(
    new Map()
  );

  const show = (ref: string) => {
    setVisibilityMap((prevMap) => new Map(prevMap).set(ref, true));
  };

  const hide = (ref: string) => {
    setVisibilityMap((prevMap) => new Map(prevMap).set(ref, false));
  };

  return (
    <VisibilityContext.Provider
      value={{
        visibilityMap,
        show,
        hide
      }}
    >
      {children}
    </VisibilityContext.Provider>
  );
};
