import { useSharedFb } from "hooks/data/useSharedFb";
import { Generation } from "types/interfaces";
import { deleteFile } from "utils/storageService";
import { useQuotasFb } from "hooks/data/useQuotasFb";

const collectionName = "generations";
const nestedCollectionName = "items";

export type GenerationsNames = "rephraserService" | "savedPrompts";

export type GenerationsType = {
  docID?: string;
  items: Generation[];
};

export const useGenerationsFb = () => {
  const { getItems, addItemMutation, removeItemMutation } = useSharedFb();
  const { decreaseQuota } = useQuotasFb();

  const generations = () => getItems(collectionName, nestedCollectionName);

  const addGeneration = (newData: Generation) =>
    addItemMutation({
      newData,
      collectionName,
      nestedCollectionName,
      onSuccess: () => {
        decreaseQuota("imageGenerations");
      }
    });

  const removeGeneration = (docID: string, fileUrl: string) =>
    removeItemMutation.mutate({
      docID,
      collectionName,
      nestedCollectionName,
      onSuccess: () => {
        deleteFile(fileUrl);
      }
    });

  return {
    generations,
    fetchStatus: {
      remove: {
        isLoading: removeItemMutation.isLoading,
        isSuccess: removeItemMutation.isSuccess,
        isError: removeItemMutation.isError
      }
    },
    addGeneration,
    removeGeneration
  };
};
