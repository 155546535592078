type FeatureFlagsType = { [key: string]: boolean };

const featureFlags: FeatureFlagsType = {
  autowriter: false,
  auth: false,
  imageGeneration: false,
  adminTools: process.env.NODE_ENV === "development" ? true : false
};

export default (customerFlugs: string[]) => {
  return Object.keys(featureFlags).reduce((acc, key) => {
    acc[key] = customerFlugs.includes(key) ? true : featureFlags[key];
    return acc;
  }, {} as FeatureFlagsType);
};
