// CustomHeading.tsx
import React from "react";
import { Heading } from "grommet";
import styled from "styled-components";

// Define the styled Heading component with custom font and size
interface StyledLogoProps {
  size: string;
  noMargin: boolean;
}

// Define the styled Heading component with custom font and size
const StyledLogo = styled(Heading)<StyledLogoProps>`
  font-family: "BD MicronFont";
  font-size: ${(props) => (props.size.includes("small") ? "40px" : "70px")};
  margin: ${(props) => (props.noMargin ? "0" : "none")};
  cursor: pointer;
`;

interface LogoProps extends React.ComponentProps<typeof Heading> {
  size: string;
  children: React.ReactNode;
  noMargin?: boolean;
}

const Logo: React.FC<LogoProps> = ({
  size,
  children,
  noMargin = false,
  ...rest
}) => {
  return (
    <StyledLogo size={size} noMargin={noMargin} {...rest}>
      {children}
    </StyledLogo>
  );
};

export default Logo;
