import { useRef, useCallback } from "react";

type RefType = HTMLElement;

const useCopyFromRef = () => {
  const elementRef = useRef<RefType>(null) as any;

  const handleCopy = useCallback(() => {
    const currentElement = elementRef.current;

    if (currentElement) {
      let textToCopy = "";

      if (
        currentElement instanceof HTMLInputElement ||
        currentElement instanceof HTMLTextAreaElement
      ) {
        currentElement.select();
        textToCopy = currentElement.value;
      } else if (
        currentElement instanceof HTMLSpanElement ||
        currentElement instanceof HTMLDivElement
      ) {
        textToCopy = currentElement.textContent || "";
      }

      navigator.clipboard.writeText(textToCopy).then(
        () => {
          console.log("Text successfully copied");
        },
        (err) => {
          console.error("Unable to copy text", err);
        }
      );
    }
  }, []);

  return {
    elementRef,
    handleCopy
  };
};

export default useCopyFromRef;
