import React from "react";
import { Box, Button } from "grommet";
import { useAdminToolsFb } from "hooks/data/useAdminToolsFb";
import { Feature } from "flagged";

const AdminTools = () => {
  const { deleteDocsNotInIds } = useAdminToolsFb();
  return (
    <Feature name="adminTools">
      <Box gap="small" fill>
        <Button
          primary
          label="Delete all group items"
          onClick={() => {
            deleteDocsNotInIds();
          }}
        />
      </Box>
    </Feature>
  );
};

export default AdminTools;
