import React from "react";
import { Heading, Page, PageContent, Text } from "grommet";

const TermsOfUse: React.FC = () => {
  return (
    <Page background="light-3" kind="narrow" pad={{ bottom: "medium" }}>
      <PageContent>
        <Heading>Cookies Policy</Heading>

        <Text>
          <Heading level="2">Introduction</Heading>
          ArtArt AI employs cookies on our platform. Your use of our service
          implies your consent to this Cookie Policy.
        </Text>
        <Text>
          <Heading level="2">Consent and Benefits</Heading>
          Your agreement to our Cookie Policy enables us to differentiate you
          from other users, enhancing your user experience through personalized
          content and continuous website improvement.
        </Text>
        <Text>
          <Heading level="2">About Cookies</Heading>
          Cookies are small text pieces sent by a website, stored in your
          browser, which facilitate a more convenient browsing experience by
          recognizing you on subsequent visits. They serve various purposes,
          including enabling certain functions, providing analytics, storing
          preferences, and delivering advertisements.
        </Text>
        <Text>
          <Heading level="2">Types of Cookies</Heading>
          <ul>
            <li>
              <b>Session Cookies:</b> Temporary, ensuring you don’t have to
              re-enter information with each page transition, deleted once the
              browser is closed.
            </li>
          </ul>
          <ul>
            <li>
              <b>Persistent Cookies:</b> Store your preferences and remain in
              your browser cache or device.
            </li>
          </ul>
          <ul>
            <li>
              <b>Essential Cookies:</b> Utilized to authenticate users and
              safeguard against fraudulent account use.
            </li>
          </ul>
          <ul>
            <li>
              <b>Third-Party Cookies:</b> Set by entities other than us,
              possibly collecting data across multiple websites or sessions.
            </li>
          </ul>
        </Text>
        <Text>
          <Heading level="2">Your Cookie Choices</Heading>
          <ul>
            <li>
              Learn more about cookies at: AllAboutCookies:{" "}
              <a href="http://www.allaboutcookies.org/ ">
                http://www.allaboutcookies.org/{" "}
              </a>
            </li>
          </ul>
          <ul>
            <li>
              Network Advertising Initiative:{" "}
              <a href="http://www.allaboutcookies.org/ ">
                http://www.networkadvertising.org/{" "}
              </a>
            </li>
          </ul>
        </Text>
        <Text>
          <Heading level="2">EU Residents</Heading>
          <ul>
            <li>
              Network Advertising Initiative:{" "}
              <a href="http://www.allaboutcookies.org/ ">
                For managing cookies and additional information on online
                privacy, visit{" "}
                <a href="https://youronlinechoices.eu">
                  https://youronlinechoices.eu
                </a>
                .
              </a>
            </li>
          </ul>
        </Text>
        <Text>
          <Heading level="2">Policy Updates</Heading>
          We may update this Cookie Policy periodically. Significant changes
          will be communicated via a website pop-up for a reasonable duration
          before and after the change.
        </Text>
        <Text>
          <Heading level="2">Contact</Heading>
          For inquiries, email us at: <b>support@artart.ai</b>.
        </Text>
      </PageContent>
    </Page>
  );
};

export default TermsOfUse;
