import * as CryptoJS from "crypto-js";
import { ThumbSize } from "types/types";

type DefaultThumb = {
  size: ThumbSize;
  dim: string;
};

export const getFileExtension = (filename: string): string | null => {
  const parts = filename.split(".");
  return parts.length > 1 ? parts.pop() || null : null;
};

export const splitFilenameAndExtension = (
  fileName: string
): {
  fileName: string;
  ext: string;
} => {
  const lastDotIndex = fileName.lastIndexOf(".");
  if (lastDotIndex === -1) {
    return { fileName, ext: "" }; // No extension found
  }
  return {
    fileName: fileName.substring(0, lastDotIndex),
    ext: fileName.substring(lastDotIndex + 1)
  };
};

export const generateUniqueFileName = (ext: string | null): string => {
  // Get the current timestamp as a unique seed
  const timestamp = new Date().getTime().toString();

  // Generate a SHA256 hash of the timestamp
  const hash = CryptoJS.SHA256(timestamp).toString(CryptoJS.enc.Hex);

  // Return the first 6 characters of the hash
  return `${hash.substring(0, 7).toLowerCase()}.${ext || "jpg"}`;
};

export const syncedData = (a: Record<string, any>, b: Record<string, any>) => ({
  ...a,
  ...Object.fromEntries(Object.entries(b).filter(([key]) => !(key in a)))
});

export const truncateText = (
  text: string,
  maxLength = 85,
  dots = true
): string =>
  text.length <= maxLength
    ? text
    : text.substring(0, maxLength - 3) + (dots ? "..." : "");

export const getFileNameFromURL = (url: string) => {
  if (!url || url.length === 0) return "";

  const regex = /\/o\/(.+)%2F(.+)\?/;
  const match = url.match(regex);
  if (match && match.length > 2) {
    return decodeURIComponent(match[2]);
  }
  return undefined;
};

export const fileNameWithPrompt = (url: string, prompt: string): string => {
  // Convert the string to lowercase
  const lowerCaseStr = truncateText(prompt, 40, false).toLowerCase();
  const underscoreStr = lowerCaseStr.replace(/[\s,]/g, "_");
  return `${underscoreStr}_${getFileNameFromURL(url)}`;
};

export const defaultThumbs: DefaultThumb[] = [
  {
    size: "small",
    dim: "350x350"
  },
  {
    size: "medium",
    dim: "512x512"
  }
];

export const getThumbName = (file: string | undefined, size: string) => {
  if (!file) return "";
  const fileSize = defaultThumbs.find((t) => t.size === size)?.dim;
  const { fileName, ext } = splitFilenameAndExtension(file);
  return `${fileName}_${fileSize}.${ext}`;
};
