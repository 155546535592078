import React from "react";
import { Box } from "grommet";
import Preloader from "components/TryAIApp/Common/Preloader";
import QuotasRemainder from "components/TryAIApp/Common/QuotasRemainder";
import useMutationsContext from "hooks/useMutationsContext";
import LazyImage from "components/TryAIApp/Common/LazyImage";
// import { useImageResizeStatusFb } from "hooks/data/useImageResizeStatusFb";
import { getFileNameFromURL, getThumbName } from "utils/helper";
import imageResizeStatusService from "utils/imageResizeStatusService";

const GeneratedImage: React.FC = () => {
  const [generatedImage, setGeneratedImage] = React.useState<string | null>(
    null
  );
  const { generateImage } = useMutationsContext();

  React.useEffect(() => {
    setGeneratedImage(null);
  }, [generateImage.isLoading]);

  if (generateImage.isSuccess) {
    imageResizeStatusService({
      fileName: getThumbName(
        getFileNameFromURL(generateImage.data?.fileUrl),
        "medium"
      ),
      onResized: () => {
        setGeneratedImage(generateImage.data?.fileUrl);
      }
    });
  }

  return (
    <>
      <Box
        pad={{ vertical: "small" }}
        height={{ min: "fit-content" }}
        direction="row"
        justify="between"
        align="center"
      >
        <QuotasRemainder quota="imageGenerations" />
      </Box>

      <Box
        direction="row"
        pad="small"
        gap="small"
        height={{ min: "fit-content" }}
        background="light-2"
        round="xsmall"
        align="center"
        justify="center"
        flex="grow"
      >
        <Box
          width={{ max: "512px" }}
          height={{ max: "512px" }}
          fill
          background="background"
        >
          {generateImage.isSuccess && generatedImage && (
            <LazyImage fill={true} src={generatedImage} size="medium" />
          )}
          {generateImage.isLoading && <Preloader />}
          {generateImage.isError && (
            <span>{(generateImage.error as { message: string }).message}</span>
          )}
        </Box>
      </Box>
    </>
  );
};

export default GeneratedImage;
