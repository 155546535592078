import React from "react";
import { Layer, Box, Heading, Button } from "grommet";
import { Close } from "grommet-icons";

interface CenteredLayerProps {
  title: string;
  onClose: () => void;
  children: React.ReactNode;
  show: boolean;
  width?: string;
}

const CenteredLayer: React.FC<CenteredLayerProps> = ({
  title,
  onClose,
  children,
  show,
  width = "large"
}) => {
  if (!show) {
    return null;
  }

  return (
    <Layer
      id="hello world"
      position="center"
      onClickOutside={onClose}
      onEsc={onClose}
    >
      <Box pad="small" gap="small" width={width}>
        <Box
          flex={false}
          direction="row"
          justify="between"
          pad={{ bottom: "medium" }}
        >
          <Heading level={3} margin="none">
            {title}
          </Heading>
          <Button icon={<Close />} onClick={onClose} plain />
        </Box>
        <Box fill>{children}</Box>
      </Box>
    </Layer>
  );
};

export default CenteredLayer;
