import { useEffect } from "react";
import useCustomerID from "hooks/useCustomerID";
import { useSharedFb } from "hooks/data/useSharedFb";
import useClientStateContext from "hooks/useClientStateContext";
import { useCustomerFb } from "hooks/data/useCustomerFb";

const useUpdateUser = () => {
  const { customerID } = useClientStateContext();
  const { savedCustomerID, clearCustomerID } = useCustomerID();
  const { updateDocID } = useSharedFb();

  const {
    customer,
    fetchStatus: { isFetched },
    updateCustomer
  } = useCustomerFb();

  useEffect(() => {
    if (savedCustomerID && !customer.isSynced) {
      ["customers", "quotas"].forEach((collectionName) => {
        updateDocID({
          oldDocID: savedCustomerID,
          newDocID: customerID,
          collectionName: collectionName
        });
      });

      ["generations", "prompts"].forEach((collectionName) => {
        updateDocID({
          oldDocID: savedCustomerID,
          newDocID: customerID,
          collectionName: collectionName,
          nestedCollectionName: "items"
        });
      });
      clearCustomerID();
    }
    if (isFetched) {
      updateCustomer(
        {
          lastVisitAt: new Date(),
          lastVisitEnv: process.env.NODE_ENV,
          isSynced: true
        },
        customerID || ""
      );
    }
  }, [isFetched]);
};

export default useUpdateUser;
