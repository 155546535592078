import { useMutation } from "@tanstack/react-query";
import imageGenerationService, {
  defaultModels
} from "utils/imageGenerationService";
import { useGenerationsFb } from "hooks/data/useGenerationsFb";
import useClientStateContext from "hooks/useClientStateContext";
import { useCustomerFb } from "hooks/data/useCustomerFb";
import imageGenerationServiceLora from "utils/imageGenerationServiceLora";
import { MlModel } from "types/interfaces";

export const useGenerateImage = (selectedMlModel: MlModel) => {
  const { addGeneration } = useGenerationsFb();
  const { customer } = useCustomerFb();
  const { prompt, generateImagePrompt, generate, generateSimplePrompt } =
    useClientStateContext();
  const textPrompts = generateImagePrompt();
  const model = customer?.model || defaultModels[0].model;

  return useMutation(
    async () => {
      const seed =
        prompt.promptParamSeed === 0
          ? Math.floor(Math.random() * 4294967296)
          : prompt.promptParamSeed;

      const fileUrl = selectedMlModel
        ? await imageGenerationServiceLora({
            textPrompts: generateSimplePrompt(selectedMlModel as MlModel),
            model: selectedMlModel?.name || "",
            seed
          })
        : await imageGenerationService({
            textPrompts,
            model,
            seed
          });

      return { fileUrl, seed };
    },
    {
      onSuccess: ({ fileUrl, seed }) => {
        addGeneration({
          fileUrl,
          model,
          promptText: generate(prompt, true),
          createdAt: new Date(),
          seed: seed
        });
      }
    }
  );
};
