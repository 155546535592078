import React, { useEffect } from "react";
import { Button, Text } from "grommet";
import { QuotaNames, useQuotasFb } from "hooks/data/useQuotasFb";
import useVisibilityContext from "hooks/useVisibilityContext";
import useNotificationsContext from "hooks/useNotificationContext";

type QuotasRemainderProps = {
  quota: QuotaNames;
  short?: boolean;
};

interface QuotasRemainderNotificationType
  extends React.ComponentProps<typeof Button> {
  onQuotaMet: () => void;
  quota: QuotaNames;
}

type QuotaMessages = {
  [key in QuotaNames]?: string;
};

const QuotasRemainder: React.FC<QuotasRemainderProps> = ({ quota, short }) => {
  const { quotas } = useQuotasFb();
  const longText = (quotaText: string) => `You have ${quotaText} remaining.`;
  const quotasText: QuotaMessages = {
    imageGenerations: short
      ? `${quotas?.imageGenerations} image generations.`
      : longText(`${quotas?.imageGenerations} image generations`),
    savedPrompts: short
      ? `${quotas?.savedPrompts} saved prompts.`
      : longText(`${quotas?.savedPrompts} saved prompts`),
    rephraserService: short
      ? `${quotas?.rephraserService} AutoWriter requests.`
      : longText(`${quotas?.rephraserService} AutoWriter requests`)
  };
  return (
    <Text size="small" color="dark-4">
      {quotasText[quota]}
    </Text>
  );
};

export const QuotasRemainderButton: React.FC<
  QuotasRemainderNotificationType
> = ({ onQuotaMet, quota, ...rest }) => {
  const { setNotifications } = useNotificationsContext();
  const { quotas } = useQuotasFb();

  const quotasText: QuotaMessages = {
    imageGenerations: `You have ${quotas?.imageGenerations} image generations remaining. Please upgrade your plan to continue using this feature.`,
    savedPrompts: `You have ${quotas?.savedPrompts} saved prompts remaining. Please upgrade your plan or delete saved prompts from asset manager to continue using this feature.`,
    rephraserService: `You have ${quotas?.rephraserService} AutoWriter requests remaining. Please upgrade your plan to continue using this feature.`
  };

  const { isVisible, hide, show, refUUID } = useVisibilityContext();

  useEffect(() => {
    setNotifications([
      {
        show: isVisible,
        message: quotasText[quota] || "",
        onClose: () => hide(refUUID),
        type: "warning"
      }
    ]);
  }, [isVisible]);

  const onClick = () => {
    if (quotas) {
      if (quotas[quota] === 0) {
        show(refUUID);
      } else {
        onQuotaMet();
      }
    }
  };

  return <Button {...rest} onClick={onClick}></Button>;
};

export default QuotasRemainder;
