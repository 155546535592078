import { useEffect, useCallback } from "react";

interface UseWebSocketChannelParams {
  socket: WebSocket | null;
  channel: string;
}

const useWebSocketChannel = ({
  socket,
  channel
}: UseWebSocketChannelParams) => {
  useEffect(() => {
    if (socket) {
      socket.onmessage = (event) => {
        if (typeof event.data === "string") {
          const data = JSON.parse(event.data);

          if (data.error) {
            console.error("WS Error", data.error);
            return;
          }
        }
      };
    } else {
      console.error("Socket is null!!!!!");
    }

    return () => {
      if (
        socket?.readyState === WebSocket.OPEN &&
        process.env.NODE_ENV !== "development"
      ) {
        socket?.close();
      }
    };
  }, [channel, socket]);

  const listen = useCallback(
    (callback: (data: any) => void) => {
      if (socket) {
        socket.onmessage = (event) => {
          let str = "";
          if (typeof event.data === "string") {
            // Handle string data
            str = event.data;
          } else if (event.data instanceof ArrayBuffer) {
            // Handle ArrayBuffer data
            const decoder = new TextDecoder("utf-8");
            str = decoder.decode(event.data);
          } else if (event.data instanceof Blob) {
            // Handle Blob data
            const reader = new FileReader();
            reader.onload = function () {
              str = reader.result as string;
              const data = JSON.parse(str);
              if (data.channel === channel) {
                callback(data.message);
              }
            };
            reader.readAsText(event.data);
            return; // Return early because FileReader is asynchronous
          }
          const data = JSON.parse(str);
          if (data.channel === channel) {
            callback(data.message);
          }
        };
      }
    },
    [socket, channel]
  );

  const push = useCallback(
    (data: any) => {
      if (socket) {
        socket.send(JSON.stringify({ channel, message: data }));
      }
    },
    [socket, channel]
  );

  return { listen, push };
};

export default useWebSocketChannel;
