import React from "react";
import { Box, Heading, Paragraph, Anchor, Button } from "grommet";
// import useResponsiveContext from "hooks/useResponsiveContext";
import useAuth from "hooks/useAuthContext";

const LandingPage: React.FC = () => {
  // const size = useResponsiveContext();
  const { signInWithGoogle } = useAuth();

  // const features = [
  //   {
  //     text: "Generate images using your photos and pictures or our pose generator"
  //   },
  //   {
  //     text: "Create your professional photo studio model"
  //   },
  //   {
  //     text: "Develop a smart prompt notepad to store and reuse your ideas, with the ability to save different variants of a particular prompt"
  //   },
  //   {
  //     text: "Smart visual prompt builder compatible with most popular AI platforms"
  //   },
  //   {
  //     text: "Utilize our AutoWriter© to rephrase, summarize, and generate quality prompts from your personal photos"
  //   },
  //   {
  //     text: "Quality image generation and upscaling"
  //   }
  // ];

  return (
    <Box pad="medium" gap="medium" width="xlarge" align="center">
      <Box align="center" width="large">
        <Heading level="1">Discover ArtArt.ai</Heading>
        <Paragraph textAlign="center" fill size="large">
          <b>ArtArt.ai</b> is a revolutionary platform that leverages advanced
          AI models to create high-quality images. It empowers users to develop
          their professional image quality models and offers a smart prompt
          helper to facilitate the creative process. Whether you are an artist,
          a photographer, or a content creator, <b>ArtArt.ai</b> provides a
          seamless experience to bring your visions to life.
        </Paragraph>
        <br />
        <br />
        <br />
        <br />
        <br />
      </Box>

      {/* <Box align="center" fill>
        <Heading level="2">Features</Heading>
        <Grid
          columns={size === "small" ? ["auto"] : ["auto", "auto", "auto"]}
          gap="small"
          rows={["auto", "auto"]}
        >
          {features.map((feature, index) => (
            <Box
              key={index}
              pad="small"
              background="light-3"
              align="center"
              justify="center"
            >
              <Text size="large" textAlign="center">
                {feature.text}
              </Text>
            </Box>
          ))}
        </Grid>
      </Box> */}

      <Box align="center">
        <Heading level="2">Supported by:</Heading>
        {/* Replace the href and src attributes with the actual links and logos of the partners */}
        <Anchor href="https://guelmanundunbekannt.com/" color="text">
          <Heading level="5">GUELMAN UND UNBEKANNT</Heading>
        </Anchor>
      </Box>

      <Box round="large" overflow="hidden">
        <Button
          primary
          label="Get Started"
          onClick={signInWithGoogle}
          size="xlarge"
        />
      </Box>
    </Box>
  );
};

export default LandingPage;
