import React, { useEffect, useState } from "react";
import { Box } from "grommet";
// import PromptBuilder from "components/TryAIApp/Stepper/PromptBuilder";
// import ArtDetails from "components/TryAIApp/Stepper/ArtDetails";
// import StylizingArea from "components/TryAIApp/Stepper/StylizingArea";
// import PromptAdjustment from "components/TryAIApp/Stepper/PromptAdjustment";
// import CopyTextArea from "components/TryAIApp/Stepper/PromptReady";
import GeneratedImagesList from "components/TryAIApp/AssetManager/GeneratedImagesList";
// import Start from "components/TryAIApp/Stepper/Start";
import Sidebar from "components/TryAIApp/Common/Sidebar";
import useCopyFromRef from "hooks/useCopyFromRef";
import { Brush, Gallery } from "grommet-icons";
// import { CaretNext, Brush, Chat, Gallery, Edit, Article } from "grommet-icons";
import useVisibilityContext from "hooks/useVisibilityContext";
import useNotificationsContext from "hooks/useNotificationContext";
import NavigationPanel from "components/TryAIApp/Common/NavigationPanel";
import { AssetManager } from "components/TryAIApp/AssetManager";
import useClientStateContext from "hooks/useClientStateContext";
import useUpdateUser from "hooks/useUpdateUser";
import GenerateImages from "components/TryAIApp/Stepper/GenerateImages";

const StepperComponent: React.FC = () => {
  const [activeStep, setActiveStep] = useState(1);
  const { prompt } = useClientStateContext();
  useUpdateUser();

  // Hooks
  const {
    isVisible: isVisibleCopied,
    hide: hideCopied,
    show: showCopied,
    refUUID: refUUIDCopied
  } = useVisibilityContext();

  const {
    isVisible: isVisibleEmptyPrompt,
    hide: hideEmptyPrompt,
    show: showEmptyPrompt,
    refUUID: refUUIDEmptyPrompt
  } = useVisibilityContext();

  const {
    isVisible: isVisibleAssetManager,
    hide: hideAssetManager,
    show: showAssetManager,
    refUUID: refUUIDAssetManager
  } = useVisibilityContext();

  const { setNotifications } = useNotificationsContext();

  const { elementRef, handleCopy } = useCopyFromRef();

  // Memos, callbacks and effects
  useEffect(() => {
    setNotifications([
      {
        show: isVisibleCopied,
        message: "Prompt is successfully copied to clipboard",
        onClose: () => hideCopied(refUUIDCopied),
        type: "warning"
      },
      {
        show: isVisibleEmptyPrompt,
        message: "Please enter prompt text of upload second image",
        onClose: () => hideEmptyPrompt(refUUIDEmptyPrompt),
        type: "error"
      }
    ]);
  }, [isVisibleCopied, isVisibleEmptyPrompt]);

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handlePrev = () => {
    setActiveStep(activeStep - 1);
  };

  const handleCopyAndNotify = () => {
    handleCopy();
    showCopied(refUUIDCopied);
  };

  const steps = [
    // {
    //   title: "Start",
    //   content: <Start />,
    //   onClick: () => alert("ss"),
    //   icon: <CaretNext />
    // },
    // {
    //   title: "Prompt Builder",
    //   content: <PromptBuilder />,
    //   onClick: () => alert("ss"),
    //   icon: <Chat />
    // },
    // {
    //   title: "Art Details",
    //   content: <ArtDetails />,
    //   onClick: () => alert("ss"),
    //   icon: <Brush />
    // },
    // {
    //   title: "Stylizing Area",
    //   content: <StylizingArea />,
    //   onClick: () => alert("ss"),
    //   icon: <Gallery />
    // },
    // {
    //   title: "Prompt Adjustment",
    //   content: <PromptAdjustment />,
    //   onClick: () => alert("ss"),
    //   icon: <Edit />
    // },
    // {
    //   title: "Prompt Ready",
    //   content: <CopyTextArea textAreaRef={elementRef} />,
    //   onClick: () => alert("ss"),
    //   icon: <Article />
    // },
    {
      title: "My Images",
      content: <GeneratedImagesList />,
      onClick: () => setActiveStep(0),
      icon: <Gallery />
    },
    {
      title: "Generate Images",
      content: <GenerateImages textAreaRef={elementRef} />,
      onClick: () => setActiveStep(1),
      icon: <Brush />
    }
  ];

  const stepIconsAndTitles = steps.map(({ title, icon, onClick }) => ({
    title,
    icon,
    onClick
  }));

  return (
    <Box direction="row" overflow="auto" fill>
      <NavigationPanel
        handleNext={
          prompt.fn?.isValid || activeStep === 0
            ? handleNext
            : () => {
                showEmptyPrompt(refUUIDEmptyPrompt);
              }
        }
        handlePrev={handlePrev}
        showAssetManager={() => showAssetManager(refUUIDAssetManager)}
        isFirstStep={activeStep === 0}
        isLastStep={activeStep === steps.length - 1}
        handleLastStepAction={handleCopyAndNotify}
      />
      <AssetManager
        show={isVisibleAssetManager}
        onClose={() => hideAssetManager(refUUIDAssetManager)}
      />
      <Sidebar steps={stepIconsAndTitles} activeStep={activeStep} />

      <Box basis="full" pad="small">
        <Box direction="row" overflow="auto" fill>
          {steps[activeStep].content}
        </Box>
      </Box>
    </Box>
  );
};

export default StepperComponent;
