import React from "react";
import { defaultPrompt, usePromptLocal } from "hooks/data/usePromptLocal";
import {
  CustomerID,
  GenerateImagePrompt,
  GenerateSimplePrompt
} from "types/types";
import { PromptLocal, Config, MlModel } from "types/interfaces";
import useAuthContext from "hooks/useAuthContext";
import { useConfigFb } from "hooks/data/useConfigFb";

type ClientStateContextType = {
  customerID: CustomerID;
  prompt: PromptLocal;
  generate: (prompt?: PromptLocal, raw?: boolean) => string;
  generateImagePrompt: () => GenerateImagePrompt[];
  generateSimplePrompt: (model: MlModel) => GenerateSimplePrompt;
  config: Config | undefined;
};

type ClientStateProviderType = {
  children: React.ReactNode;
};

export const ClientStateContext = React.createContext<ClientStateContextType>({
  customerID: "",
  prompt: defaultPrompt,
  generate: () => "",
  generateImagePrompt: () => [],
  generateSimplePrompt: (): GenerateSimplePrompt =>
    ({}) as GenerateSimplePrompt,

  config: {} as Config
});

export const ClientStateProvider: React.FC<ClientStateProviderType> = ({
  children
}) => {
  const { user } = useAuthContext();
  const { prompt, generate, generateImagePrompt, generateSimplePrompt } =
    usePromptLocal();
  const { config } = useConfigFb();

  return (
    <ClientStateContext.Provider
      value={{
        customerID: user?.uid || "",
        prompt,
        generate,
        generateImagePrompt,
        generateSimplePrompt,
        config
      }}
    >
      <>{children}</>
    </ClientStateContext.Provider>
  );
};
