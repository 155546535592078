import { GenerateSimpleImage } from "types/interfaces";
import { uploadBase64 } from "utils/storageService";
import { v4 as uuidv4 } from "uuid";

export default async ({
  textPrompts,
  seed,
  model
}: GenerateSimpleImage): Promise<string> => {
  const url = `${process.env.REACT_APP_LORA_API}/sdapi/v1/txt2img`;
  const negativeDefault =
    "(bad-artist:1.0), (loli:1.2), (worst quality, low quality:1.4), (bad_prompt_version2:0.8), bad-hands-5,lowres, bad anatomy, bad hands, ((text)), (watermark), error, missing fingers, extra digit, fewer digits, cropped, worst quality, low quality, normal quality, ((username)), blurry, (extra limbs), bad-artist-anime, badhandv4, EasyNegative, ng_deepnegative_v1_75t, verybadimagenegative_v1.3, BadDream,(three hands:1.1),(three legs:1.1),(more than two hands:1.4),(more than two legs,:1.2)";

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json"
      // Authorization: `Bearer ${process.env.REACT_APP_STABILITY_KEY}`
    },
    body: JSON.stringify({
      prompt: textPrompts.prompt,
      negative_prompt: `${textPrompts.negative} ${negativeDefault}`,
      seed: seed,
      sampler_name: "DPM++ SDE Karras",
      batch_size: 1,
      n_iter: 1,
      steps: 30,
      cfg_scale: 7,
      width: 1024,
      height: 1024,
      restore_faces: true
    })
  });

  if (response.status === 429) {
    throw new Error("Rate limit exceeded");
  }

  if (!response.ok) {
    throw new Error("Failed to generate image");
  }

  const resp = await response.json();

  console.log("model", model);

  const uploadURL = uploadBase64({
    base64: resp.images[0],
    fileName: `${uuidv4()}.png`
  });

  // const urlObject = URL.createObjectURL(blob);
  return uploadURL;
};
