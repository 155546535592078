import { useSharedFb } from "hooks/data/useSharedFb";
import { db } from "utils/firebase";
import { doc, getDoc } from "firebase/firestore";
import { useMutation } from "@tanstack/react-query";

const collectionName = "quotas";

export type QuotaNames =
  | "rephraserService"
  | "savedPrompts"
  | "imageGenerations";

export type QuotasType = Record<QuotaNames, number> & {
  docID?: string;
};

const defaultQuotas: QuotasType = {
  rephraserService: 25,
  savedPrompts: 10,
  imageGenerations: 80
};

export const useQuotasFb = () => {
  const { findOrCreate, updateMutation, customerID } = useSharedFb();

  const { data, isLoading, isError, isFetched } = findOrCreate(
    defaultQuotas,
    collectionName
  );

  const updateQuotas = async (
    updatedData: Partial<QuotasType>,
    docID: string
  ) => {
    await updateMutation({
      updatedData,
      docID,
      collectionName
    });
  };

  const adjustQuota = useMutation(
    async ({ quota, decrease }: { quota: QuotaNames; decrease: boolean }) => {
      const docRef = doc(db, collectionName, customerID);
      const docSnap = await getDoc(docRef);
      const data = docSnap.data() as QuotasType;

      const newQuotaValue = Math.max(
        0,
        decrease ? data[quota] - 1 : data[quota] + 1
      );
      return { [quota]: newQuotaValue };
    },
    {
      onSuccess: (updatedQuota: Partial<QuotasType>) => {
        updateQuotas(updatedQuota, customerID);
      }
    }
  );

  const decreaseQuota = (quota: QuotaNames) => {
    adjustQuota.mutate({ quota, decrease: true });
  };

  const increaseQuota = (quota: QuotaNames) => {
    adjustQuota.mutate({ quota, decrease: false });
  };

  return {
    quotas: data,
    fetchStatus: {
      isLoading,
      isError,
      isFetched
    },
    decreaseQuota,
    increaseQuota
  };
};
