import React, { useEffect } from "react";
import { Avatar, Box, Button, Heading, TextInput } from "grommet";
import { Copy } from "grommet-icons";
import CenteredLayer from "components/TryAIApp/Common/CenteredLayer";
import useCopyFromRef from "hooks/useCopyFromRef";
import useClientStateContext from "hooks/useClientStateContext";
import useNotificationsContext from "hooks/useNotificationContext";
import useVisibilityContext from "hooks/useVisibilityContext";
import useAuthContext from "hooks/useAuthContext";
import QuotasRemainder from "components/TryAIApp/Common/QuotasRemainder";

interface UserConfigsProps {
  onClose: () => void;
  show: boolean;
}

const UserConfigs: React.FC<UserConfigsProps> = ({ onClose, show }) => {
  const { elementRef, handleCopy } = useCopyFromRef();
  const { customerID } = useClientStateContext();
  const { setNotifications } = useNotificationsContext();
  const { user, signOutUser } = useAuthContext();

  const {
    isVisible: isVisibleCopied,
    hide: hideCopied,
    show: showCopied,
    refUUID: refUUIDCopied
  } = useVisibilityContext();

  useEffect(() => {
    setNotifications([
      {
        show: isVisibleCopied,
        message: "CustomerID is successfully copied to clipboard",
        onClose: () => hideCopied(refUUIDCopied),
        type: "warning"
      }
    ]);
  }, [isVisibleCopied]);

  const handleCopyAndNotify = () => {
    handleCopy();
    showCopied(refUUIDCopied);
  };

  const handleOnSignOut = () => {
    signOutUser();
    onClose();
  };

  return (
    <CenteredLayer
      title="User Configs"
      onClose={onClose}
      show={show}
      width="medium"
    >
      <Box gap="medium" align="center">
        <Heading level={4} margin="none" fill>
          Welcome! {user?.displayName}
        </Heading>
        <Avatar size="large" src={user?.photoURL || ""} />

        <Heading level={5} margin="none" fill>
          Subscribtion plan:
        </Heading>
        <Box pad={{ vertical: "small" }} align="center">
          <QuotasRemainder quota="imageGenerations" short />
          <QuotasRemainder quota="rephraserService" short />
          <QuotasRemainder quota="savedPrompts" short />
        </Box>
        <Heading level={5} margin="none" fill>
          CustomerID:
        </Heading>
        <Box fill="horizontal" direction="row" align="center">
          <TextInput ref={elementRef} value={customerID || ""} />
          <Button icon={<Copy size="medium" />} onClick={handleCopyAndNotify} />
        </Box>
        <Box round="large" overflow="hidden">
          <Button
            primary
            size="large"
            onClick={handleOnSignOut}
            label="Sign Out"
          />
        </Box>
      </Box>
    </CenteredLayer>
  );
};

export default UserConfigs;
