import React, { useState } from "react";
import { Box, Button, Stack, Tip, Text } from "grommet";
import { Trash, Download, Info } from "grommet-icons";
import { Generation } from "types/interfaces";
import LazyImage from "components/TryAIApp/Common/LazyImage";
import { useGenerationsFb } from "hooks/data/useGenerationsFb";
import { downloadFile } from "utils/storageService";
import useCopyFromRef from "hooks/useCopyFromRef";

type BoxProps = React.ComponentProps<typeof Box>;

interface GeneratedImagesItemProps extends Pick<BoxProps, "basis"> {
  generation: Generation;
  handleNotify: () => void;
}
const GeneratedImagesItem: React.FC<GeneratedImagesItemProps> = ({
  generation,
  basis,
  handleNotify
  // removeGeneration
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const { fetchStatus, removeGeneration } = useGenerationsFb();
  const { elementRef, handleCopy } = useCopyFromRef();

  const handleCopyAndNotify = () => {
    handleCopy();
    handleNotify();
  };

  const handleDelete = () => {
    setIsDeleted(true);
    setTimeout(() => {
      removeGeneration(generation.docID || "", generation.fileUrl);
    }, 300); // 300ms matches the transition duration
  };

  console.log("GeneratedImagesItem", generation.fileUrl, generation.promptText);

  return (
    <Box
      basis={basis}
      id={generation.docID}
      flex={false}
      pad="xsmall"
      onMouseEnter={() => {
        setIsHovered(true);
      }}
      onMouseLeave={() => setIsHovered(false)}
      style={{
        transition: "all 0.3s ease, transform 0.3s ease",
        maxWidth: isDeleted ? "0" : "100%",
        overflow: "hidden"
      }}
      // animation={{ type: "zoomOut", duration: 200 }}
    >
      <Stack anchor="center" fill>
        <LazyImage src={generation.fileUrl} fill={true} size="small" />
        <Box
          style={{
            opacity: isHovered && !fetchStatus.remove.isLoading ? 1 : 0,
            transition: "opacity 0.3s ease"
          }}
          align="center"
          direction="row"
          gap="xsmall"
          justify="between"
          round="xlarge"
          elevation="medium"
          pad="xxsmall"
          background="text"
        >
          <Tip
            plain
            content={
              <Box
                pad="small"
                gap="small"
                width={{ max: "small" }}
                round="xsmall"
                background="background-front"
                responsive={false}
              >
                <Text weight="bold" ref={elementRef}>
                  {generation.seed}
                </Text>
                <Text size="small">{generation.promptText}</Text>
              </Box>
            }
            dropProps={{ align: { left: "right" } }}
          >
            <Box round="full" overflow="hidden" background="light-2">
              <Button
                icon={<Info size="small" />}
                hoverIndicator
                onClick={handleCopyAndNotify}
              />
            </Box>
          </Tip>
          <Box round="full" overflow="hidden" background="light-2">
            <Button
              icon={<Download size="small" />}
              hoverIndicator
              onClick={() =>
                downloadFile(generation.fileUrl, generation.promptText)
              }
            />
          </Box>
          <Box round="full" overflow="hidden" background="light-2">
            <Button
              icon={<Trash size="small" />}
              hoverIndicator
              onClick={() => handleDelete()}
            />
          </Box>
        </Box>
      </Stack>
    </Box>
  );
};

export default GeneratedImagesItem;
