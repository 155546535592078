import { db } from "utils/firebase";
import {
  collection,
  getDocs,
  deleteDoc,
  query,
  where,
  doc
} from "firebase/firestore";

export const useAdminToolsFb = () => {
  const queryByDate = async (date: string) => {
    const targetDate = new Date(date); // Replace with the date you are interested in
    targetDate.setHours(0, 0, 0, 0); // Set time to midnight

    const nextDay = new Date(targetDate);
    nextDay.setDate(targetDate.getDate() + 1); // One day ahead

    const q = query(
      collection(db, "customers"),
      // where("lastVisitAt", ">=", targetDate),
      // where("lastVisitAt", "<", nextDay),
      // where("model", "==", "stable-diffusion-xl-1024-v1-0"),
      // where("lastVisitEnv", "==", "development")
      where("featureFlags", "array-contains", "imageGeneration")
    );

    const querySnapshot = await getDocs(q);
    let count = 0; // Initialize counter

    const docIds: string[] = [];

    querySnapshot.forEach((doc) => {
      const data = doc.data();
      // if (data.featuresFlags && data.featuresFlags.length > 0) {
      docIds.push(doc.id);
      console.log(doc.id, "=>", data);
      count++;
      // }
    });

    console.log(`Total count of documents that meet the criteria: ${count}`);

    return docIds;
  };

  const deleteGroupItems = async () => {
    // queryByDate("2023-09-15")
    queryByDate("2023-09-16").then(async (docIds) => {
      const batchSize = 10;
      for (let i = 0; i < docIds.length; i += batchSize) {
        const batchIds = docIds.slice(i, i + batchSize);

        // Using 'in' query operator
        const q = query(
          collection(db, "quotas"),
          where("__name__", "not-in", batchIds)
        );
        const querySnapshot = await getDocs(q);

        const deletePromises: Promise<void>[] = []; // Explicitly specify the type as Promise<void>[]

        console.log(`Deleting ${querySnapshot.size} documents...`);
        querySnapshot.forEach((doc) => {
          // console.log(`Deleting document with ID: ${doc.id}`);
          deletePromises.push(deleteDoc(doc.ref));
        });

        // Wait for all delete operations to complete
        await Promise.all(deletePromises);

        console.log(
          `Deleted ${deletePromises.length} documents in this batch.`
        );
      }

      console.log("All specified documents have been deleted.");
    });
  };

  const deleteDocsNotInIds = async () => {
    const collectionName = "nonexisting";
    queryByDate("2023-09-16").then(async (docIds) => {
      // Create a query to get all documents in the collection
      const q = query(collection(db, collectionName));

      // Fetch all documents
      const querySnapshot = await getDocs(q);

      // Loop through all documents and delete those not in idChunk
      querySnapshot.forEach(async (docSnapshot) => {
        const docId = docSnapshot.id;
        if (!docIds.includes(docId)) {
          await deleteDoc(doc(db, collectionName, docId));
        }
      });
    });
  };

  return {
    queryByDate,
    deleteDocsNotInIds,
    deleteGroupItems
  };
};
