import { db } from "utils/firebase";
import { doc, onSnapshot } from "firebase/firestore";

const collectionName = "imageResizes";

export default async ({
  fileName,
  onResized
}: {
  fileName: string | undefined;
  onResized: () => void;
}) => {
  if (!fileName || fileName.length === 0) return false;
  const imageResizeRef = doc(db, collectionName, fileName);

  onSnapshot(imageResizeRef, (snapshot) => {
    const data = snapshot.data();
    if (data && data.resized) {
      onResized();
    }
  });
};
