import { GenerateImage, Model } from "types/interfaces";
import { uploadBlob } from "utils/storageService";
import { v4 as uuidv4 } from "uuid";

export const defaultModels: Model[] = [
  {
    model: "stable-diffusion-xl-1024-v1-0",
    size: 1024
  },
  {
    model: "stable-diffusion-xl-beta-v2-2-2",
    size: 512
  },
  {
    model: "stable-diffusion-512-v2-1",
    size: 512
  },
  {
    model: "stable-diffusion-v1-5",
    size: 512
  }
];

export default async ({
  textPrompts,
  seed,
  model
}: GenerateImage): Promise<string> => {
  const url = `${process.env.REACT_APP_STABILITY_AI_API}/v1/generation/${model}/text-to-image`;

  const modelSize = defaultModels.find((m) => m.model === model)?.size;

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "image/png",
      Authorization: `Bearer ${process.env.REACT_APP_STABILITY_KEY}`
    },
    body: JSON.stringify({
      text_prompts: textPrompts,
      cfg_scale: 7,
      height: modelSize,
      width: modelSize,
      samples: 1,
      steps: 30,
      seed: seed
    })
  });

  if (response.status === 429) {
    throw new Error("Rate limit exceeded");
  }

  if (!response.ok) {
    throw new Error("Failed to generate image");
  }

  const blob = await response.blob();

  const uploadURL = uploadBlob({
    blob,
    fileName: `${uuidv4()}.png`
  });

  // const urlObject = URL.createObjectURL(blob);
  return uploadURL;
};
