import { useEffect, useState } from "react";
import * as marked from "marked";
import { Box, ResponsiveContext } from "grommet";
import Logo from "components/TryAIApp/Common/Logo";

const Changelog = () => {
  const [changelog, setChangelog] = useState("");

  useEffect(() => {
    fetch("/CHANGELOG.md")
      .then((response) => response.text())
      .then((text) => {
        setChangelog(marked.parse(text));
      })
      .catch((error) => {
        console.error("Error fetching CHANGELOG.md:", error);
      });
  }, []);

  return (
    <Box gap="small">
      <Box align="center">
        <ResponsiveContext.Consumer>
          {(size) => (
            <Box>
              <Logo size={size}>{process.env.REACT_APP_LOGO}</Logo>
            </Box>
          )}
        </ResponsiveContext.Consumer>
      </Box>

      <Box pad="xlarge">
        <span dangerouslySetInnerHTML={{ __html: changelog }} />
      </Box>
    </Box>
  );
};

export default Changelog;
