import React from "react";
import { Box } from "grommet";
import { RangeInput, TextArea, TextInput } from "grommet/components";
import SelectMultiple from "components/TryAIApp/Common/SelectMultiple";
import useClientStateContext from "hooks/useClientStateContext";
import GeneratedImage from "components/TryAIApp/Common/GenerateImage";
import InformationHeader from "components/TryAIApp/Common/InformationHeader";
import { promptTextSceneStyleOptions } from "configs/options";

interface PromptReadyProps {
  textAreaRef: React.RefObject<HTMLTextAreaElement>;
}

const PromptReady: React.FC<PromptReadyProps> = ({ textAreaRef }) => {
  const { prompt } = useClientStateContext();
  console.log(textAreaRef);
  return (
    <Box gap="medium" fill direction="column">
      <Box gap="medium" fill direction="row-responsive">
        <Box gap="medium" fill="horizontal">
          <Box gap="medium" fill="horizontal">
            <InformationHeader title="Prompt">
              <>
                <span>
                  <b>Define the Image:</b> Start by clearly defining what kind
                  of image you want to generate. Is it a landscape, a character,
                  an object, or an abstract concept?
                </span>
                <span>
                  <b>Detail the Elements:</b> Describe the elements you want in
                  the image. Be specific about colors, shapes, and any other
                  details that are important.
                </span>
                <span>
                  <b>Set the Mood:</b> Describe the mood or atmosphere of the
                  image. This could be happy, eerie, calm, chaotic, etc.
                </span>
                <span>
                  <b>Use Action Words:</b> Start your prompt with a verb to
                  encourage action. For example, "Generate an image of...",
                  "Create a scene where...".
                </span>
                <span>
                  <b>Provide Context:</b> Make sure the AI knows why it's
                  generating this image. Include brief context if necessary.
                </span>
                <span>
                  <b>Be Clear and Concise:</b> Your prompt should be easy to
                  understand. Avoid jargon and complex sentences. Keep it short
                  and to the point.
                </span>
                <span>
                  <b>Test and Refine:</b> Test your prompt with the AI. If the
                  generated image doesn't meet your expectations, refine the
                  prompt and try again.
                </span>
              </>
            </InformationHeader>
            <TextArea
              placeholder=""
              value={prompt.promptText}
              onChange={(eventA: { target: { value: string } }) =>
                prompt.fn?.update?.({
                  promptText: eventA.target.value
                })
              }
            />
          </Box>
          <Box gap="medium" fill="horizontal" direction="row-responsive">
            <Box gap="medium" fill="horizontal">
              <InformationHeader title="Negative prompting">
                Negative prompting in AI refers to providing explicit
                instructions or constraints to an AI system to prevent it from
                generating or focusing on certain elements or concepts in its
                outputs. In the specific example you mentioned, "--no plants
                would try to remove plants from the image," negative prompting
                is used to instruct an AI model to avoid generating or including
                any plants in the image it produces.
              </InformationHeader>
              <TextInput
                placeholder=""
                value={prompt.promptParamNo}
                onChange={(event) =>
                  prompt.fn?.update?.({
                    promptParamNo: event.target.value
                  })
                }
              />
            </Box>
            <Box gap="medium" fill="horizontal">
              <InformationHeader title="Seed">
                Seed numbers are generated randomly for each image but can be
                specified with the seed parameter. If you use the same seed
                number and prompt, you will get similar final images. Find the
                seed number of a Job in discord by reacting with an ✉️ envelope
                emoji to a Job.
              </InformationHeader>
              <TextInput
                placeholder=""
                type="number"
                value={prompt.promptParamSeed}
                onChange={(event) =>
                  prompt.fn?.update?.({
                    promptParamSeed: Number(event.target.value)
                  })
                }
              />
            </Box>
          </Box>
        </Box>
        <Box gap="medium" fill="horizontal">
          <InformationHeader title="Style">
            Scene style, in terms of image generation, refers to a specific
            artistic or thematic representation of a scene or setting. It
            encompasses various visual elements, such as lighting, colors,
            composition, and overall aesthetics, to create a distinct and
            recognizable look or atmosphere for the image. For example, a
            "Cinematic" scene style might focus on high contrast, dramatic
            lighting, and deep shadows, similar to how scenes in movies are
            often shot. On the other hand, a "Hyper Realistic" style aims to
            produce images that closely resemble real-life scenes, with
            meticulous attention to detail and accuracy.
          </InformationHeader>
          <SelectMultiple
            updatedFIeld={(value) =>
              prompt.fn?.update?.({ promptTextSceneStyle: value })
            }
            value={prompt.promptTextSceneStyle.split(", ")}
            options={promptTextSceneStyleOptions}
          />

          <Box gap="medium" fill="horizontal" direction="row-responsive">
            <Box gap="medium" fill="horizontal">
              <InformationHeader
                title={`Enhance the effect - ${prompt.promptParamEnhanceEffectLevel}`}
              >
                In the context of generating pictures using AI, "remix" refers
                to the process of combining and manipulating existing images to
                create new and unique visuals. The AI-powered image remixing can
                involve blending multiple images, extracting elements from one
                image and adding them to another, or altering the colors,
                textures, or other visual characteristics of the original
                images.
              </InformationHeader>
              <TextInput
                placeholder=""
                value={prompt.promptParamEnhanceEffect}
                onChange={(event) =>
                  prompt.fn?.update?.({
                    promptParamEnhanceEffect: event.target.value
                  })
                }
              />

              <RangeInput
                min={0}
                max={2}
                step={0.1}
                disabled={!prompt.fn?.isEnhanceEffect}
                value={prompt.promptParamEnhanceEffectLevel}
                onChange={(event) =>
                  prompt.fn?.update?.({
                    promptParamEnhanceEffectLevel: Number(event.target.value)
                  })
                }
              />
            </Box>
            <Box gap="medium" fill="horizontal">
              <InformationHeader
                title={`Chaos Level - ${prompt.promptParamChaosLevel}%`}
              >
                The chaos level can be controlled by adjusting various
                parameters of the generative algorithm, such as the level of
                randomness, the number of iterations, or the degree of noise
                added to the image. For instance, a low chaos level may produce
                more orderly and repetitive patterns, while a high chaos level
                may produce more complex and chaotic patterns.
              </InformationHeader>
              <TextInput
                placeholder=""
                value={prompt.promptParamChaosLevel}
                disabled
                // onChange={(event) =>
                //   prompt.fn?.update?.({
                //     promptParamEnhanceEffect: event.target.value
                //   })
                // }
              />
              <RangeInput
                value={prompt.promptParamChaosLevel}
                onChange={(event) =>
                  prompt.fn?.update?.({
                    promptParamChaosLevel: event.target.value
                  })
                }
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box gap="medium" fill>
        <GeneratedImage />
      </Box>
    </Box>
  );
};

export default PromptReady;
