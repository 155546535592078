import { useSharedFb } from "hooks/data/useSharedFb";
import { MlModel } from "types/interfaces";

const collectionName = "mlModels";
const nestedCollectionName = "items";

export type MlModelsType = {
  docID?: string;
  items: MlModel[];
};

export const useMlModelsFb = () => {
  const { getItems, addItemMutation, removeItemMutation } = useSharedFb();

  const { data, isLoading, isError } = getItems(
    collectionName,
    nestedCollectionName
  );

  const addMlModel = (newData: MlModel) =>
    addItemMutation({
      newData,
      collectionName,
      nestedCollectionName
    });

  const removeMlModel = (docID: string) =>
    removeItemMutation.mutate({
      docID,
      collectionName,
      nestedCollectionName
    });

  return {
    mlModels: data,
    fetchStatus: {
      get: {
        isLoading,
        isError
      },
      remove: {
        isLoading: removeItemMutation.isLoading,
        isSuccess: removeItemMutation.isSuccess,
        isError: removeItemMutation.isError
      }
    },
    addMlModel,
    removeMlModel
  };
};
