import React from "react";
import { useParams } from "react-router-dom";
import { Box, ResponsiveContext } from "grommet";
import useWebSocketChannel from "hooks/useWebSocketChannel";
import socket from "utils/websocketService";
import Dropzone from "components/TryAIApp/Uploader/DropZone";
import Logo from "components/TryAIApp/Common/Logo";

const Uploader = () => {
  const { customerID } = useParams();
  const { push } = useWebSocketChannel({
    socket,
    channel: `channel-${customerID}`
  });

  const sendMessage = (message: string) => {
    push(message);
  };

  return (
    <Box gap="small" fill>
      <Box align="center">
        <ResponsiveContext.Consumer>
          {(size) => (
            <Box>
              <Logo size={size}>{process.env.REACT_APP_LOGO}</Logo>
            </Box>
          )}
        </ResponsiveContext.Consumer>
      </Box>

      <Box pad="large" fill justify="center">
        <Dropzone
          onUpload={(url) => {
            sendMessage(url);
          }}
        />
      </Box>
    </Box>
  );
};

export default Uploader;
