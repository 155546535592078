import React from "react";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import { FlagsProvider } from "flagged";
import StepperComponent from "components/TryAIApp/Stepper";
import Uploader from "components/TryAIApp/Uploader";
import Changelog from "components/TryAIApp/Pages/Changelog";
// import WebsocketAuth from "components/TryAIApp/Common/WebsocketAuth";
import { NotificationsProvider } from "contexts/NotificationsContext";
import { Main } from "grommet";
import featureFlags from "configs/featureFlags";
import AdminTools from "components/TryAIApp/AdminTools";
import RecoverID from "components/TryAIApp/Pages/RecoverID";
import { useCustomerFb } from "hooks/data/useCustomerFb";
import Landing from "components/TryAIApp/Landing";
import useAuthContext from "hooks/useAuthContext";

const MainUI = () => {
  const { customer } = useCustomerFb();
  return (
    <FlagsProvider features={featureFlags(customer?.featureFlags || [])}>
      <NotificationsProvider>
        <Main>
          {/* <WebsocketAuth> */}
          <Router>
            <Routes>
              <Route path="/" element={<StepperComponent />} />
              <Route path="/upload/:customerID" element={<Uploader />} />
              <Route path="/recoverid/:customerID" element={<RecoverID />} />
              <Route path="/changelog" element={<Changelog />} />
              <Route path="/admin" element={<AdminTools />} />
            </Routes>
          </Router>
          {/* </WebsocketAuth> */}
        </Main>
      </NotificationsProvider>
    </FlagsProvider>
  );
};

const DefaultUI = () => {
  const { user } = useAuthContext();
  return user ? (
    <MainUI />
  ) : (
    <Router>
      <Landing />
    </Router>
  );
};

export default DefaultUI;
