import React from "react";
import { Close, Document, Image } from "grommet-icons";
import { Box, Button, Layer, Text } from "grommet";
import PromptsList from "components/TryAIApp/AssetManager/PromptsList";
import useNotificationsContext from "hooks/useNotificationContext";
import GeneratedImagesList from "components/TryAIApp/AssetManager/GeneratedImagesList";
import QuotasRemainder from "components/TryAIApp/Common/QuotasRemainder";
import { useQuotasFb } from "hooks/data/useQuotasFb";

interface AssetManagerType {
  onClose: () => void;
  show: boolean;
}

type Tabs = "prompts" | "generations";

type Tab = {
  [key in Tabs]?: {
    quotas: string;
    render: JSX.Element;
  };
};

export const AssetManager: React.FC<AssetManagerType> = ({ show, onClose }) => {
  const { quotas } = useQuotasFb();
  const { closeAll } = useNotificationsContext();
  const [tab, setTab] = React.useState<Tabs>("prompts");

  if (!show) {
    return null;
  }

  const closeAllAndClose = () => {
    closeAll();
    onClose();
  };

  const tabs: Tab[] = [
    {
      prompts: {
        quotas: `${quotas?.savedPrompts} saved prompts left.`,
        render: <PromptsList />
      }
    },
    {
      generations: {
        quotas: `${quotas?.savedPrompts} saved prompts left.`,
        render: <GeneratedImagesList />
      }
    }
  ];

  return (
    <Layer full="vertical" position="right" onEsc={closeAllAndClose}>
      <Box fill width={{ min: "medium" }}>
        <Box
          direction="row"
          align="center"
          as="header"
          justify="between"
          background="text"
        >
          <Box direction="row">
            <Button
              hoverIndicator
              onClick={() => setTab("prompts")}
              active={tab === "prompts"}
            >
              <Box pad="small" direction="row" align="center" gap="small">
                <Document />
                <Text>Prompts</Text>
              </Box>
            </Button>
            <Button
              hoverIndicator
              onClick={() => setTab("generations")}
              active={tab === "generations"}
            >
              <Box pad="small" direction="row" align="center" gap="small">
                <Image />
                <Text>Generations</Text>
              </Box>
            </Button>
          </Box>

          <Button icon={<Close />} onClick={closeAllAndClose} />
        </Box>
        <Box flex overflow="auto" background="background">
          {tabs.find((t) => t[tab])?.[tab]?.render}
        </Box>
        <Box as="footer" pad="small" direction="row" align="center">
          <QuotasRemainder quota="savedPrompts" />
        </Box>
      </Box>
    </Layer>
  );
};
