import React, { useEffect, useState } from "react";
import { Radial, RadialSelected, Trash } from "grommet-icons";
import { Accordion, AccordionPanel, Box, Text } from "grommet";
import { truncateText } from "utils/helper";
import useClientStateContext from "hooks/useClientStateContext";
import useVisibilityContext from "hooks/useVisibilityContext";
import useNotificationsContext from "hooks/useNotificationContext";
import { usePromptsFb } from "hooks/data/usePromptsFb";
import Preloader from "components/TryAIApp/Common/Preloader";

const mappedPrompts = (prompts: any) => {
  // Create an empty object to hold the mapping
  const mappedObj: { [key: string]: any[] } = {};

  // Loop through each object in the array
  for (const prompt of prompts) {
    // If the title is not already a key in the object, add it with an empty array as its value
    if (!mappedObj[prompt.promptText]) {
      mappedObj[prompt.promptText] = [];
    }

    // Push the current object to the array corresponding to its title
    mappedObj[prompt.promptText].push(prompt);
  }

  // Convert the object to an array of objects with the desired structure
  const mappedArray = Object.entries(mappedObj).map(([key, value]) => ({
    title: key,
    variants: value
  }));

  return mappedArray;
};

// HIGHLIGHT DIFFERENCES

// TODO: REFACTOR TO GET DIFFERENCES IN TWO OBJECTS {...obj2, ...obj1} - to detect only different fields values
// const findDifferences = (prev: any, curr: any): string[] => {
//   const diffKeys: string[] = [];
//   for (const key in prev) {
//     if (prev[key] !== curr[key]) {
//       diffKeys.push(key);
//     }
//   }
//   return diffKeys;
// };

// const wrapInBold = (text: string): string => {
//   // console.log(text);
//   return `${text}`;
// };

// const checkDifferences = (prompts: any[]): any[] => {
//   return prompts.reduce((acc: any[], curr: any, index: number) => {
//     if (index === 0) {
//       acc.push({ ...curr, diff: [] });
//     } else {
//       const prev = acc[index - 1];
//       const diff = findDifferences(prev, curr);
//       const newCurr: any = { ...curr };
//       for (const key of diff) {
//         newCurr[key] = wrapInBold(curr[key]);
//       }

//       acc.push({ ...newCurr, diff });
//     }
//     return acc;
//   }, []);
// };

// END HIGHLIGHT DIFFERENCES

const renderLabel = (label: string, active: boolean) => (
  <Box pad="small" justify="between" direction="row">
    <Text>{active ? truncateText(label, 10) : truncateText(label)}</Text>
    {active && 1 < 0 && (
      <Box direction="row" gap="small">
        <RadialSelected color="status-error" />
        <Radial color="status-warning" />
        <RadialSelected
          color="status-ok"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        />
        <Radial color="brand" />
      </Box>
    )}
  </Box>
);

const PromptsList = () => {
  const [activeIndex, setActiveIndex] = useState([-1]);
  const { generate, prompt: mainPrompt } = useClientStateContext();
  const { prompts, removePrompt, fetchStatus } = usePromptsFb();

  const { setNotifications } = useNotificationsContext();

  const {
    isVisible: isVisiblePromptNotification,
    hide: hidePromptNotification,
    show: showPromptNotification,
    refUUID: refUUIDPromptNotification
  } = useVisibilityContext();

  useEffect(() => {
    setNotifications([
      {
        show: isVisiblePromptNotification,
        message: `Prompt is successfully removed from the list`,
        onClose: () => hidePromptNotification(refUUIDPromptNotification),
        type: "warning"
      }
    ]);
  }, [isVisiblePromptNotification]);

  return fetchStatus.isLoading ? (
    <Preloader />
  ) : (
    <Box width={{ max: "medium" }}>
      <Accordion
        activeIndex={activeIndex}
        onActive={(newActiveIndex) => setActiveIndex(newActiveIndex)}
      >
        {mappedPrompts(prompts).map((promptVariants, index) => (
          <AccordionPanel
            header={renderLabel(
              promptVariants.title,
              activeIndex.includes(index)
            )}
            key={index}
          >
            <Box>
              {promptVariants.variants.map((prompt: any, index: number) => (
                <Box
                  onClick={() => mainPrompt.fn?.update?.(prompt)}
                  pad="small"
                  flex={false}
                  direction="row"
                  justify="between"
                  background={`dark-${index % 2 == 0 ? "2" : "3"}`}
                  key={index}
                >
                  {generate(prompt)}
                  <Trash
                    onClick={(e) => {
                      showPromptNotification(refUUIDPromptNotification);
                      removePrompt(prompt.docID);
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  />
                </Box>
              ))}
            </Box>
          </AccordionPanel>
        ))}
      </Accordion>
    </Box>
  );
};

export default PromptsList;
