import React, { useState } from "react";
import Notification, {
  NotificationType
} from "components/TryAIApp/Common/Notification";

type NotificationsContextType = {
  setNotifications: (notification: NotificationType[]) => void;
  closeAll: () => void;
};

type NotificationsProviderType = {
  children: React.ReactNode;
};

// Create the context with a default value
export const NotificationsContext =
  React.createContext<NotificationsContextType>({
    setNotifications: () => {},
    closeAll: () => {}
  });

export const NotificationsProvider: React.FC<NotificationsProviderType> = ({
  children
}) => {
  const [notifications, setNotifications] = useState<NotificationType[]>([]);

  const addNotifications = (newNotifications: NotificationType[]) => {
    setNotifications((prevNotifications) => {
      const updatedNotifications = prevNotifications.map((prevNotif) => {
        const matchingNewNotif = newNotifications.find(
          (newNotif) => newNotif.message === prevNotif.message
        );

        if (matchingNewNotif && matchingNewNotif.show !== prevNotif.show) {
          return matchingNewNotif; // Replace with new notification if 'show' is different
        }
        return prevNotif; // Otherwise, keep the existing notification
      });

      const uniqueNewNotifications = newNotifications.filter(
        (newNotif) =>
          !prevNotifications.some(
            (prevNotif) => prevNotif.message === newNotif.message
          )
      );

      return [...updatedNotifications, ...uniqueNewNotifications];
    });
  };

  const closeAll = () => {
    setNotifications((prevNotifications) => {
      return prevNotifications.map((notif) => ({
        ...notif,
        show: false
      }));
    });
  };

  return (
    <NotificationsContext.Provider
      value={{
        setNotifications: addNotifications,
        closeAll
      }}
    >
      <>
        {children}
        {notifications.map(({ show, message, onClose, type }, index) => (
          <Notification
            key={index}
            show={show}
            message={message}
            onClose={onClose}
            type={type}
          />
        ))}
      </>
    </NotificationsContext.Provider>
  );
};
