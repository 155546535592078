import { useSharedFb } from "hooks/data/useSharedFb";
import { useQuotasFb } from "hooks/data/useQuotasFb";
import { PromptLocal } from "types/interfaces";

const collectionName = "prompts";
const nestedCollectionName = "items";

export type Prompt = Omit<PromptLocal, "fn">;

export const usePromptsFb = () => {
  const { getItems, addItemMutation, removeItemMutation } = useSharedFb();
  const { decreaseQuota, increaseQuota } = useQuotasFb();

  const { data, isLoading, isError } = getItems(
    collectionName,
    nestedCollectionName
  );

  const addPrompt = (newData: Prompt) =>
    addItemMutation({
      newData,
      collectionName,
      nestedCollectionName,
      onSuccess: () => {
        decreaseQuota("savedPrompts");
      }
    });

  const removePrompt = (docID: string) =>
    removeItemMutation.mutate({
      docID,
      collectionName,
      nestedCollectionName,
      onSuccess: () => {
        increaseQuota("savedPrompts");
      }
    });

  return {
    addPrompt,
    removePrompt,
    prompts: data,
    fetchStatus: {
      isLoading,
      isError
    }
  };
};
