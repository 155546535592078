import React, { useEffect, useState } from "react";
import { SelectMultiple } from "grommet";

interface SelectWrapperProps {
  options: string[];
  updatedFIeld: (value: string) => void;
  value?: string[];
}

export const SelectWrapper: React.FC<SelectWrapperProps> = ({
  options,
  updatedFIeld,
  value = []
}) => {
  const [optionsList, setOptionsList] = useState(options);
  const [valueMultiple, setValueMultiple] = useState(value);

  useEffect(() => {
    updatedFIeld(valueMultiple.join(", "));
  }, [valueMultiple]);

  return (
    <SelectMultiple
      showSelectedInline
      dropProps={{
        width: "medium"
      }}
      // icon={<CaretDown />}
      // icon={false}
      disabled={[]}
      value={valueMultiple}
      placeholder="Select"
      options={optionsList}
      dropHeight="medium"
      onSearch={(text) => {
        // The line below escapes regular expression special characters:
        // [ \ ^ $ . | ? * + ( )
        const escapedText = text.replace(/[-\\^$*+?.()|[\]{}]/g, "\\$&");

        // Create the regular expression with modified value which
        // handles escaping special characters. Without escaping special
        // characters, errors will appear in the console
        const exp = new RegExp(escapedText, "i");
        setOptionsList(options.filter((o) => exp.test(o)));
      }}
      onClose={() => setOptionsList(options)}
      onChange={({ value }) => {
        setValueMultiple(value);
      }}
    />

    // </Grommet>
  );
};

export default SelectWrapper;
