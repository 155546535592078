import React from "react";
import { Box, Anchor, Button, Footer } from "grommet";
import Logo from "components/TryAIApp/Common/Logo";
import useAuth from "hooks/useAuthContext";
import { Route, Routes, useNavigate } from "react-router-dom";
import Main from "components/TryAIApp/Landing/Main";
import TermsOfUse from "components/TryAIApp/Landing/TermsOfUse";
import CopyrightPolicy from "components/TryAIApp/Landing/CopyrightPolicy";
import UserContentPolicy from "components/TryAIApp/Landing/UserContentPolicy";
import CookiesPolicy from "components/TryAIApp/Landing/CookiesPolicy";

const LandingPage: React.FC = () => {
  const { signInWithGoogle } = useAuth();
  const navigate = useNavigate();

  return (
    <Box align="center" justify="center">
      <Box
        pad="medium"
        background="light-1"
        fill="horizontal"
        direction="row"
        align="center"
        justify="between"
      >
        <Logo
          size="small"
          noMargin
          onClick={() => {
            navigate("/");
          }}
        >
          ArtArtAI
        </Logo>
        <Box round="large" overflow="hidden">
          <Button
            primary
            label="Get Started"
            onClick={signInWithGoogle}
            size="xlarge"
          />
        </Box>
      </Box>

      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/terms-of-use" element={<TermsOfUse />} />
        <Route path="/copyright-policy" element={<CopyrightPolicy />} />
        <Route path="/user-content-policy" element={<UserContentPolicy />} />
        <Route path="/cookies-policy" element={<CookiesPolicy />} />
      </Routes>

      <Footer background="light-1" pad="medium" fill align="center">
        <Box direction="row-responsive" gap="medium">
          <Box direction="column" gap="xsmall">
            <Anchor
              label="Terms of Use"
              color="text"
              size="xsmall"
              onClick={() => navigate("terms-of-use")}
            />
            <Anchor
              label="Copyright Policy"
              color="text"
              size="xsmall"
              onClick={() => navigate("copyright-policy")}
            />
          </Box>
          <Box direction="column" gap="xsmall">
            <Anchor
              label="User Content Policy"
              color="text"
              size="xsmall"
              onClick={() => navigate("user-content-policy")}
            />
            <Anchor
              label="Cookies Policy"
              color="text"
              size="xsmall"
              onClick={() => navigate("cookies-policy")}
            />
          </Box>
        </Box>
        <Anchor href="email: support@artart.ai" color="text">
          Contact us
        </Anchor>
      </Footer>
    </Box>
  );
};

export default LandingPage;
