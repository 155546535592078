import { useCallback, useEffect, useState } from "react";
import md5 from "blueimp-md5";
import { MlModel, PromptLocal } from "types/interfaces";
import {
  GenerateImagePrompt,
  GenerateSimplePrompt,
  PromptItem
} from "types/types";

export const defaultPrompt: PromptLocal = {
  promptTextCamera: "",
  promptTextColor: "",
  promptTextImage: "",
  promptTextFilters: "",
  promptTextPaintingTechnique: "",
  promptTextPaintingTechniqueAccent: false,
  promptTextPaintingTechniqueEnhance: 0,
  promptTextSceneStyle: "",
  promptTextShotType: "",
  promptText: "",
  promptParamAspectRatio: "4:3",
  promptParamChaosLevel: "0",
  promptParamEnhanceEffect: "",
  promptParamEnhanceEffectLevel: 0,
  promptParamNo: "",
  promptParamSeed: 0,
  promptParamStylizeLevel: "0",
  promptParamsTile: false,
  promptParamWeirdLevel: "0"
};

export const usePromptLocal = () => {
  const [prompt, setPrompt] = useState<PromptLocal>(defaultPrompt);
  const [isDirty, setIsDirty] = useState<boolean>(true);

  const update = (updatedPrompt: PromptItem): Promise<void> => {
    return new Promise((resolve, reject) => {
      try {
        setPrompt((prevPrompt) => ({ ...prevPrompt, ...updatedPrompt }));
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  };

  // CALLBACKS
  const isPromptTextPaintingTechnique = useCallback(() => {
    return prompt.promptTextPaintingTechnique.length !== 0;
  }, [prompt.promptTextPaintingTechnique]);

  const isLandscapeLayout = useCallback(() => {
    const [sideA, sideB] = prompt.promptParamAspectRatio.split(":").map(Number);
    return sideA > sideB;
  }, [prompt.promptParamAspectRatio]);

  const isEnhanceEffect = useCallback(() => {
    return prompt.promptParamEnhanceEffect.length !== 0;
  }, [prompt.promptParamEnhanceEffect]);

  const isValid = useCallback(() => {
    return prompt.promptText.length > 0;
  }, [prompt.promptText]);

  // EFFECTS
  useEffect(() => {
    setIsDirty(true);
  }, [prompt]);

  useEffect(() => {
    if (!isPromptTextPaintingTechnique()) {
      update({
        promptTextPaintingTechniqueAccent: false,
        promptTextPaintingTechniqueEnhance: 0
      });
    }
  }, [isPromptTextPaintingTechnique]);

  useEffect(() => {
    if (!prompt.promptTextPaintingTechniqueAccent) {
      update({
        promptTextPaintingTechniqueEnhance: 0
      });
    }
  }, [prompt.promptTextPaintingTechniqueAccent]);

  useEffect(() => {
    if (isEnhanceEffect() && prompt.promptParamEnhanceEffectLevel === 0) {
      update({
        promptParamEnhanceEffectLevel: 0.1
      });
    }

    if (!isEnhanceEffect()) {
      update({
        promptParamEnhanceEffectLevel: 0
      });
    }
  }, [isEnhanceEffect, prompt.promptParamEnhanceEffectLevel]);

  // FUNCTIONS
  function promptTextPaintingTechniqueObjectName(
    _prompt: Omit<PromptLocal, "fn">
  ): string {
    return typeof prompt.promptTextPaintingTechnique === "string"
      ? _prompt.promptTextPaintingTechnique
      : (
          _prompt.promptTextPaintingTechnique as unknown as {
            name: string;
            value: string;
          }
        )?.name;
  }

  function promptTextPaintingTechniqueObjectValue(
    _prompt: Omit<PromptLocal, "fn">
  ): string {
    return typeof _prompt.promptTextPaintingTechnique === "string"
      ? _prompt.promptTextPaintingTechnique
      : (_prompt.promptTextPaintingTechnique as { name: string; value: string })
          ?.value;
  }

  function switchPromptParamAspectRatio() {
    const ar = prompt.promptParamAspectRatio;
    const [sideA, sideB] = ar.split(":").map(Number);

    update({ promptParamAspectRatio: `${sideB}:${sideA}` });
  }

  const generate = (_prompt = prompt, raw = false) => {
    const promptTextSceneStyle =
      _prompt.promptTextSceneStyle === ""
        ? ""
        : `${_prompt.promptTextSceneStyle}`;
    const promptParamEnhanceEffectWithLevel =
      _prompt.promptParamEnhanceEffectLevel > 0 &&
      _prompt.promptParamEnhanceEffect.length > 0
        ? `${_prompt.promptParamEnhanceEffect}::${_prompt.promptParamEnhanceEffectLevel}`
        : "";
    const promptTextImage =
      _prompt.promptTextImage === "" ? "" : _prompt.promptTextImage;
    const promptTextPaintingTechnique =
      _prompt.promptTextPaintingTechniqueAccent
        ? ""
        : promptTextPaintingTechniqueObjectName(_prompt);
    const promptTextPaintingTechniqueEnhance =
      _prompt.promptTextPaintingTechniqueEnhance > 0
        ? `${promptTextPaintingTechniqueObjectName(_prompt)}::${
            _prompt.promptTextPaintingTechniqueEnhance
          }`
        : "";
    const promptTextCamera =
      _prompt.promptTextCamera === ""
        ? ""
        : `${_prompt.promptTextCamera} camera`;
    const promptTextColor =
      _prompt.promptTextColor === ""
        ? ""
        : `in ${_prompt.promptTextColor} colors`;
    const promptParamNo =
      _prompt.promptParamNo === "" ? "" : `--no ${_prompt.promptParamNo}`;
    const promptTextFilters =
      _prompt.promptTextFilters === ""
        ? ""
        : `filters: ${_prompt.promptTextFilters}`;
    const promptText = _prompt.promptText === "" ? "" : _prompt.promptText;
    const promptTextShotType =
      _prompt.promptTextShotType === "" ? "" : _prompt.promptTextShotType;
    const promptParamStylizeLevel =
      _prompt.promptParamStylizeLevel === "0"
        ? ""
        : `--stylize ${_prompt.promptParamStylizeLevel}`;
    const promptParamWeirdLevel =
      _prompt.promptParamWeirdLevel === "0"
        ? ""
        : `--weird ${_prompt.promptParamWeirdLevel}`;
    const promptParamChaosLevel =
      _prompt.promptParamChaosLevel === "0"
        ? ""
        : `--chaos ${_prompt.promptParamChaosLevel}`;
    const promptParamAspectRatio = `--ar ${_prompt.promptParamAspectRatio}`;
    const promptParamSeed =
      _prompt.promptParamSeed === 0 ? "" : `--seed ${_prompt.promptParamSeed}`;
    const promptParamsTile = _prompt.promptParamsTile ? `--tile` : "";

    const promptTextPrefix = _prompt.promptTextPaintingTechniqueAccent
      ? `${promptTextPaintingTechniqueObjectValue(_prompt)} of `
      : "";

    const promptWithImage = [promptTextPrefix, promptTextImage, promptText]
      .filter((item: string) => item !== "")
      .join(" ");

    const promptWithoutImage = [promptTextPrefix, promptText]
      .filter((item: string) => item !== "")
      .join(" ");

    const promptWIthImageAndStyles = [
      promptWithImage,
      promptTextSceneStyle,
      promptTextPaintingTechnique,
      promptTextCamera,
      promptTextShotType,
      promptTextColor,
      promptTextFilters
    ]
      .filter((item: string) => item !== "")
      .join(", ");

    const promptWIthoutImageAndStyles = [
      promptWithoutImage,
      promptTextSceneStyle,
      promptTextPaintingTechnique,
      promptTextCamera,
      promptTextShotType,
      promptTextColor,
      promptTextFilters
    ]
      .filter((item: string) => item !== "")
      .join(", ");

    const promptOptions = [
      promptParamEnhanceEffectWithLevel,
      promptTextPaintingTechniqueEnhance,
      promptParamNo,
      promptParamAspectRatio,
      promptParamChaosLevel,
      promptParamSeed,
      promptParamStylizeLevel,
      promptParamWeirdLevel,
      promptParamsTile
    ]
      .filter((item: string) => item !== "")
      .join(" ");
    return raw
      ? promptWIthoutImageAndStyles
      : [`${promptWIthImageAndStyles}:: `, promptOptions].join(" ");
  };

  const generateImagePrompt = (): GenerateImagePrompt[] => {
    const text = generate(prompt, true); // Assuming generate is a function you've defined
    const negative = prompt.promptParamNo;
    const enhance = prompt.promptParamEnhanceEffect;
    const enhanceLevel = prompt.promptParamEnhanceEffectLevel;

    const result: GenerateImagePrompt[] = [];

    // Always include the 'text' field
    result.push({ text });

    // Include 'negative' if it exists and has length > 0
    if (negative && negative.length > 0) {
      result.push({ text: negative, weight: -0.8 });
    }

    // Include 'enhance' if it exists and has length > 0
    if (enhance && enhance.length > 0) {
      result.push({ text: enhance, weight: enhanceLevel });
    }

    return result;
  };

  const generateSimplePrompt = (model: MlModel): GenerateSimplePrompt => {
    const text = generate(prompt, true);
    const negative = prompt.promptParamNo;

    const indexOfHumanisedPrompt = text.indexOf(model.humanisedPrompt);
    const enhance = "1.6";

    const outputText =
      indexOfHumanisedPrompt === -1
        ? text
        : text.replace(
            model.humanisedPrompt,
            `${model.prompt}:${enhance} ${model.category}`
          ) + `<${model.type.toLowerCase()}:${model.name}:1>`;

    return { prompt: outputText, negative: negative };
  };

  const getMd5 = (_prompt = prompt) => md5(generate(_prompt));

  return {
    prompt: {
      ...prompt,
      md5: getMd5(prompt),
      fn: {
        update,
        switchPromptParamAspectRatio,
        isValid: isValid(),
        isLandscapeLayout: isLandscapeLayout(),
        isPromptTextPaintingTechnique: isPromptTextPaintingTechnique(),
        isEnhanceEffect: isEnhanceEffect(),
        isDirty,
        setIsDirty
      }
    },
    generate,
    generateImagePrompt,
    generateSimplePrompt
  };
};
