import { useQuery } from "@tanstack/react-query";
import { doc, getDoc } from "firebase/firestore";
import { db } from "utils/firebase";
import { useSharedFb } from "hooks/data/useSharedFb";
import { Customer } from "types/interfaces";

const collectionName = "customers";

export const defaultCustomer: Customer = {
  role: "customer",
  nickname: "Anonymous",
  model: "stable-diffusion-xl-beta-v2-2-2",
  lastVisitAt: new Date(),
  isSynced: false,
  lastVisitEnv: process.env.NODE_ENV,
  featureFlags: []
};

export const useCustomerFb = () => {
  const { updateMutation, findOrCreate } = useSharedFb();

  const { data, isLoading, isError, isFetched } = findOrCreate(
    defaultCustomer,
    collectionName
  );

  const getCustomerByDocID = async (docID: string) => {
    const docRef = doc(db, collectionName, docID);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return { ...docSnap.data(), docID: docSnap.id } as Customer;
    } else {
      throw new Error("Customer not found");
    }
  };

  const findByID = (docID: string) => {
    return useQuery(["customer", docID], () => getCustomerByDocID(docID));
  };

  const updateCustomer = async (
    updatedData: Partial<Customer>,
    docID: string
  ) => {
    await updateMutation({
      updatedData,
      docID,
      collectionName
    });
  };

  return {
    customer: data,
    updateCustomer,
    findByID,
    fetchStatus: {
      isLoading,
      isError,
      isFetched
    }
  };
};
