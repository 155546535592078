import React from "react";
import { Card, Heading, Page, PageContent, Text } from "grommet";

const TermsOfUse: React.FC = () => {
  return (
    <Page background="light-3" kind="narrow" pad={{ bottom: "medium" }}>
      <PageContent gap="medium">
        <Heading>Copyright Policy</Heading>

        <Text>
          Submit notices of alleged copyright infringement to{" "}
          <b>support@artart.ai</b>
          or to Durer's legal team at Keane Vgenopoulou & Associates LLC,
          <b>info@lkaw.eu</b>. Ensure the notification is detailed, including
        </Text>

        <Card background="text" pad="large">
          <ul>
            <li>The signature of the copyright owner or authorized person;</li>
            <li>Identification of each infringed copyrighted work;</li>
            <li>
              Location of the allegedly infringing material on the ArtArtAI
              platform (include URL);
            </li>
            <li>
              Complaining party's contact details: full name, address, phone,
              and email;
            </li>
            <li>
              A statement of good faith belief that the work is used unlawfully;
            </li>
            <li>
              A statement confirming the accuracy of the notification and that
              the complaining party is authorized to act on behalf of the
              copyright owner.
            </li>
          </ul>
        </Card>
      </PageContent>
    </Page>
  );
};

export default TermsOfUse;
