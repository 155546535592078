import React, { useEffect, useState } from "react";
import { Layer, Box, Button } from "grommet";
import { Menu, Previous, Next, Copy, DocumentUpdate } from "grommet-icons";
import useClientStateContext from "hooks/useClientStateContext";
import useNotificationsContext from "hooks/useNotificationContext";
import useVisibilityContext from "hooks/useVisibilityContext";
import { Feature } from "flagged";
import { QuotasRemainderButton } from "components/TryAIApp/Common/QuotasRemainder";
import useMutationsContext from "hooks/useMutationsContext";
import { usePromptsFb } from "hooks/data/usePromptsFb";
import useAuthContext from "hooks/useAuthContext";

type NavigationPanelType = {
  handleNext: () => void;
  handlePrev: () => void;
  handleLastStepAction: () => void;
  showAssetManager: () => void;
  isLastStep: boolean;
  isFirstStep: boolean;
};

type LayerPositionType =
  | "top"
  | "bottom"
  | "left"
  | "right"
  | "center"
  | "top-left"
  | "bottom-left"
  | "top-right"
  | "bottom-right";

const NavigationPanel: React.FC<NavigationPanelType> = ({
  handleNext,
  handlePrev,
  handleLastStepAction,
  showAssetManager,
  isLastStep,
  isFirstStep
}) => {
  const { prompt } = useClientStateContext();
  const { addPrompt } = usePromptsFb();
  const { generateImage } = useMutationsContext();
  const { setNotifications } = useNotificationsContext();
  const [position, setPosition] = useState<LayerPositionType>("bottom-right");
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const {
    isVisible: isVisiblePromptNotification,
    hide: hidePromptNotification,
    show: showPromptNotification,
    refUUID: refUUIDPromptNotification
  } = useVisibilityContext();

  const { user, signInWithGoogle } = useAuthContext();

  useEffect(() => {
    setNotifications([
      {
        show: isVisiblePromptNotification,
        message: `Prompt is successfully added to the list`,
        onClose: () => hidePromptNotification(refUUIDPromptNotification),
        type: "warning"
      }
    ]);
  }, [isVisiblePromptNotification]);

  const savePrompt = () => {
    const { fn, ...newPrompt } = prompt;
    addPrompt(newPrompt);
    prompt.fn?.setIsDirty?.(false);
  };

  const handleDragStart = (e: React.DragEvent<HTMLDivElement>) => {
    setIsDragging(true);
    setDragStart({ x: e.clientX, y: e.clientY });
  };

  const handleDragEnd = (e: React.DragEvent<HTMLDivElement>) => {
    const xDifference = e.clientX - dragStart.x;
    const yDifference = e.clientY - dragStart.y;

    let newVerticalPosition: string = position.includes("top")
      ? "top"
      : "bottom";

    let newHorizontalPosition = "";

    // Determine vertical position
    if (yDifference < -20) {
      newVerticalPosition = "top";
    } else if (yDifference > 20) {
      newVerticalPosition = "bottom";
    }

    // Determine horizontal position based on existing position
    if (position.includes("-left")) {
      newHorizontalPosition = "-left";
    } else if (position.includes("-right")) {
      newHorizontalPosition = "-right";
    }

    // Update horizontal position based on drag direction
    if (xDifference > 10) {
      if (newHorizontalPosition === "-left") {
        newHorizontalPosition = "";
      } else if (newHorizontalPosition !== "-right") {
        newHorizontalPosition = "-right";
      }
    } else if (xDifference < -10) {
      if (newHorizontalPosition === "-right") {
        newHorizontalPosition = "";
      } else if (newHorizontalPosition !== "-left") {
        newHorizontalPosition = "-left";
      }
    }

    const newPosition: LayerPositionType = (newVerticalPosition +
      newHorizontalPosition) as LayerPositionType;

    setPosition(newPosition);
    setIsDragging(false);
  };

  return (
    <Layer
      position={position}
      modal={false}
      margin={{ vertical: "medium", horizontal: "small" }}
      responsive={false}
      plain
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      draggable
      style={{ opacity: isDragging ? 80 : 100 }}
    >
      {user ? (
        <Box
          align="center"
          direction="row"
          gap="small"
          justify="between"
          round="xlarge"
          elevation="medium"
          pad="xsmall"
          background="text"
        >
          <Feature name="hidde">
            <Box round="full" overflow="hidden" background="light-2">
              <Button
                icon={<Previous />}
                hoverIndicator
                onClick={handlePrev}
                disabled={isFirstStep}
              />
            </Box>

            <Box round="full" overflow="hidden" background="light-2">
              <Button
                icon={isLastStep ? <Copy /> : <Next />}
                hoverIndicator
                onClick={isLastStep ? handleLastStepAction : handleNext}
              />
            </Box>
            {isLastStep && prompt.fn?.isDirty && (
              <Box round="full" overflow="hidden" background="light-2">
                <QuotasRemainderButton
                  quota="savedPrompts"
                  icon={<DocumentUpdate />}
                  hoverIndicator
                  onQuotaMet={() => {
                    savePrompt();
                    showPromptNotification(refUUIDPromptNotification);
                  }}
                />
              </Box>
            )}
          </Feature>
          <Feature name="imageGeneration">
            {isLastStep && (
              <Button
                label="Generate"
                onClick={() => {
                  generateImage.reset();
                  generateImage.mutate();
                }}
              />
            )}
          </Feature>
          <Box round="full" overflow="hidden" background="light-2">
            <Button icon={<Menu />} hoverIndicator onClick={showAssetManager} />
          </Box>
        </Box>
      ) : (
        <Box round="large" overflow="hidden">
          <Button
            primary
            size="large"
            onClick={signInWithGoogle}
            label="Get Started"
          />
        </Box>
      )}
    </Layer>
  );
};

export default NavigationPanel;
