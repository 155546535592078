import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytes,
  uploadString
} from "firebase/storage";
import { ThumbSize } from "types/types";
import { storage } from "utils/firebase";
import {
  fileNameWithPrompt,
  getFileNameFromURL,
  getThumbName
} from "utils/helper";

type UploadBlobProps = {
  blob: Blob;
  fileName: string;
};

type UploadBase64Props = {
  base64: string;
  fileName: string;
};

const storageFolder = "generations";

export const getThumbURL = async (file: string, size: ThumbSize) => {
  const thumbName = getThumbName(file, size);
  const storageRef = ref(storage, `${storageFolder}/thumbs/${thumbName}`);

  const downloadURL = await getDownloadURL(storageRef);
  return downloadURL;
};

export const uploadBlob = async ({ blob, fileName }: UploadBlobProps) => {
  const storageRef = ref(storage, `${storageFolder}/${fileName}`);
  await uploadBytes(storageRef, blob);
  const downloadURL = await getDownloadURL(storageRef);
  return downloadURL;
};

export const uploadBase64 = async ({ base64, fileName }: UploadBase64Props) => {
  const storageRef = ref(storage, `${storageFolder}/${fileName}`);

  const metadata = {
    contentType: "image/png"
  };

  await uploadString(storageRef, base64, "base64", metadata);
  const downloadURL = await getDownloadURL(storageRef);
  return downloadURL;
};

export const deleteFile = async (fileUrl: string) => {
  const fileName = getFileNameFromURL(fileUrl);
  const storageRef = ref(storage, `${storageFolder}/${fileName}`);
  try {
    await deleteObject(storageRef);
    return "File deleted successfully";
  } catch (error) {
    return `Error deleting file: ${error}`;
  }
};

export const downloadFile = async (fileUrl: string, prompt: string) => {
  const fileName = getFileNameFromURL(fileUrl);
  const fileNamePrompt = fileNameWithPrompt(fileUrl, prompt);

  const storageRef = ref(storage, `${storageFolder}/${fileName}`);
  const url = await getDownloadURL(storageRef);

  fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = fileNamePrompt; // Replace 'file_name' with the actual file name
      link.click();
    })
    .catch((error) => console.error("Error downloading the file:", error));
};
