import React from "react";
import { useGenerateImage } from "hooks/useGenerateImage";
import { useMlModelsFb } from "hooks/data/useMlModelsFb";
import { MlModel } from "types/interfaces";
type MutationsContextType = {
  generateImage?: any;
  mlModels: MlModel[] | undefined;
  selectedMlModel?: MlModel;
  setSelectedMlModel: (mlModel: MlModel | undefined) => void;
};

type MutationsProviderType = {
  children: React.ReactNode;
};

export const MutationsContext = React.createContext<MutationsContextType>({
  generateImage: () => {},
  mlModels: [] as MlModel[],
  setSelectedMlModel: () => {}
});

export const MutationsProvider: React.FC<MutationsProviderType> = ({
  children
}) => {
  const { mlModels } = useMlModelsFb();
  const [selectedMlModel, setSelectedMlModel] = React.useState<
    MlModel | undefined
  >(undefined);
  const generateImage = useGenerateImage(selectedMlModel as MlModel);
  return (
    <MutationsContext.Provider
      value={{ generateImage, mlModels, selectedMlModel, setSelectedMlModel }}
    >
      <>{children}</>
    </MutationsContext.Provider>
  );
};
